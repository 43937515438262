/**
 * Advanced Search provides functionality where the filter parameters will be updated with the search string parameters, if necessary.
 * 
 * Search Syntax:
 *      `searchterm param:value param:"value with spaces" searchterm ...`
 *  
*/

import moment from 'moment';

// Contain all of the keywords to recognise
const SEARCH_PARAMS = [
    {
        keywords: ["milestone", "milestones", "ms"], // Keywords to trigger this processor
        processor: (value, filter, searchParams, index) => { // Processes the Keywords and updates the filter
            let maxLookahead = 4; // The index is the starting point

            // See if we start on this value (if there isn't a space after the ':')
            let before = false; // true if 'before'
            let after = false; // true if 'after'
            let range = false; // true if searching in a range of dates
            let dateStart = ""; // Date to start looking
            let dateEnd = ""; // Date to end looking
            if (value) {
                maxLookahead -= 1; // Since first part of the value is already here
                if (moment(value).isValid()) {
                    // This is a date
                    range = true;
                    dateStart = value;
                } else {
                    before = value.toLowerCase() === "before";
                    after = value.toLowerCase() === "after";
                }
            }
            let curr = index;
            while (curr < index + maxLookahead && curr < searchParams.length) {
                let val = searchParams[curr];
                // In the beginning there should be either a before, after, or start date
                if (!range && !before && !after && moment(val).isValid()) {
                    range = true;
                    dateStart = val;
                } else if (!range && !before && !after) {
                    before = val.toLowerCase() === "before";
                    after = val.toLowerCase() === "after";
                } else 
                // Now we look for the date if before or after are true
                if (before && !dateEnd && moment(val).isValid()) {
                    dateEnd = val;
                    break;
                } else if (after && !dateStart && moment(val).isValid()) {
                    dateStart = val;
                    break;
                } else
                // Range has something like a "-" in the middle, but we don't really care about that
                // What we do care about is the end date of a range
                if (range && !dateEnd && moment(val).isValid()) {
                    dateEnd = val;
                    break;
                }
                curr++;
            }

            // Do validation before changing the filter or searchParams so that we don't get bad filter values or a broken search string
            if (!dateStart && !dateEnd) {
                return false;
            }

            if (range && !(dateStart && dateEnd)) {
                return false;
            }

            // Remove extra parameters so that search isn't broken
            if (curr > index) {
                searchParams.splice(index + 1, curr - index); // Remove the dates, and before/after/...
                index -= curr - index;
            }

            if (dateStart || dateEnd) {
                filter.milestoneDate = {}
            }

            if (dateStart) {
                filter.milestoneDate.startDate = dateStart;
            }

            if (dateEnd) {
                filter.milestoneDate.endDate = dateEnd;
            }

            if (filter.milestoneDate) {
                if (!filter.filter_order) {
                    filter.filter_order = [];
                }
                if (filter.filter_order.indexOf("milestoneDate") === -1) {
                    filter.filter_order.push("milestoneDate");
                }
                return true;
            }
            
        },
        resetFilter: (filter) => { // Resets the filter if one had previously been put in place
            __resetFilterHelper(filter, "milestoneDate");
        }
    }
]

// Make the SEARCH_PARAMS more convenient to work with
let searchKeywords = {};
SEARCH_PARAMS.forEach((param) => {
    param.keywords.forEach((keyword) => {
        searchKeywords[keyword] = {};
        if (param.processor) {
            searchKeywords[keyword].processor = param.processor;
        } else {
            searchKeywords[keyword].processor = _defaultProcessor;
        }
    });
});

/**
 * Default Processor for the Search Parameters
 * @param {String} value Parameter value
 * @param {Object} filter the filter from the filter menu
 * @param {Array} searchParams All of the search parameters
 * @param {Number} index Current Index in the searchParameters
 * @returns {Boolean} True if the parameters are valid, false otherwise
 */
function _defaultProcessor(value, filter, searchParams, index) {
    // @TODO make this generic for the other filter parameters
    return false;
}

/**
 * Removes parameters from the filter
 * @param {Object} filter Filter Object
 */
function _defaultResetFilter(filter) {
    // @TODO Implement a default handler that takes the name in as a parameter too
    // Do nothing
}

// Removes parameters from the filter
function __resetFilterHelper(filter, name) {
    if (filter && filter[name]) {
        delete filter[name];
    }
    if (filter && filter.filter_order && filter.filter_order.indexOf(name) > -1) {
        filter.filter_order.splice(filter.filter_order.indexOf(name), 1); // Remove the filter order
        if (!filter.filter_order.length) {
            delete filter.filter_order;
        }
    }
}

/**
 * Returns Search Parameters from a string
 * @param {String} search Search String
 * @returns {Array} Search Parameters
 */
export function GetSearchParameters(search) {
    if (search && search.split) {
        return search.split(' ');
    }
    return [];
}

/**
 * Returns a Search String from search Parameters
 * @param {Array} search Split Search array
 * @returns {String} Search String to pass to Search function
 */
export function GetSearchStringWithoutParameters(search) {
    let toRet = [];
    if (search && search.length > 0) {
        search.forEach((item) => {
            // Exclude search parameters and empty strings
            if (item && item.indexOf(":") === -1) {
                toRet.push(item);
            }
        });
    }
    return toRet.join(" ");
}


/**
 * Updates the Filter with the search parameters
 * @param {Object} searchParams Search Parameters
 * @param {Object} filter Filter Object
 * @returns {Object} Updated Filter
 */
export function SearchProcessor(searchParams, filter) {
    let foundKeywords = []; // Manages found keywords and the filter

    if (searchParams && searchParams.length > 0) {
        for (let i = 0; i < searchParams.length; i++) {
            let param = searchParams[i];
            if (param.indexOf(":") > 0) {
                let split = param.split(":"); // parameter:value
                if (searchKeywords[split[0]]) {
                    let res = searchKeywords[split[0]].processor(split[1], filter, searchParams, i);
                    // Only push found, valid parameters
                    if (res) {
                        foundKeywords.push(split[0]);
                    }
                }
            }
        }
    }
    
    if (foundKeywords.length > 0) {
        SEARCH_PARAMS.forEach((param) => {
            let found = false;
            for (let i = 0; i < param.keywords.length && !found; i++) {
                if (foundKeywords.includes(param.keywords[i])) {
                    found = true;
                }
            }
            if (!found) {
                // Note: We don't have a use for the default Reset Filter function yet.
                if (param.resetFilter) {
                    param.resetFilter(filter);
                }
            }
        });
    } else if (filter.filter_order && filter.filter_order.length > 0) {
        // Since there were no found keywords, we need to reset all of the filters, if there have been filters applied
        SEARCH_PARAMS.forEach((param) => {
            // Note: We don't have a use for the default Reset Filter function yet.
            if (param.resetFilter) {
                param.resetFilter(filter);
            }
        });
    }

    return filter;
}