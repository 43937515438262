// The reducer will take this `AUTHENTICATED_USER` string and determine the next state
import axios from 'axios';
import uuid from 'uuid';
import moment from 'moment';

import { 

    GET_AUTHENTICATED_USER, 
    UPDATE_AUTHENTICATED_USER,
    LOGIN_USER,
    GET_REPOS,
    UPDATE_REPOS,
    SET_CURRENT_PROJECT,
    CURRENT_REPO_PROECT_INFO,
    UPDATE_PROJECTS_FOR_ORG,
    UPDATE_COLUMN_STATE,
    UPDATE_HOPPER_STATE,
    FILTER_FOR_PROJECT,
    SELECTED_FILTER,
    UPDATE_FILTER,
    SEARCH_PROJECT,
    ALL_PROJECTS_FOR_ALL_ORG,
    GET_PROJECT,
    HOPPER_LOADING_STATE,
    RECOMMENDED_REPOS

} from "../constants/action-types";

import { ColumnProcessor } from "../processors/ProjectColumnProcesor";

import { GET_CURRENT_USER } from "../../graphql/GetProfile";
import { GET_REPO_PROJECT_DETAIL } from "../../graphql/GetRepoProjectDetail";
import { GET_PROJECTS_FOR_ORG_QUERY } from "../../graphql/GetProjectsForOrg";
import { GET_PROJECTS_FOR_USER_QUERY } from "../../graphql/GetProjectsForUser"
import { UpdateColumn, AddProjectColumn, UpdateProjectColumn,  AddProject } from "../../graphql/mutations";
import { API, graphqlOperation } from 'aws-amplify'

import * as Github from "../../github/requests";
import { getIssuesAndPR } from "../processors/GetIssuesPullRequestsV3"
import { FilterProcessor } from "../../components/utils/FilterProcessor";

import recommendedRepos from 'workerize-loader!../../workers/RecommendedRepos.js'; // eslint-disable-line import/no-webpack-loader-syntax

function getJsonFromUrl (hashBased) {
    var query;
    if (hashBased) {
        var pos = window.location.href.indexOf("?");
        if (pos == -1) return [];
        query = window.location.href.substr(pos + 1);
    } else {
        query = window.location.search.substr(1);
    }
    var result = {};
    query.split("&").forEach(function (part) {
        if (!part) return;
        part = part.split("+").join(" "); // replace every + with space, regexp-free version
        var eq = part.indexOf("=");
        var key = eq > -1 ? part.substr(0, eq) : part;
        var val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : "";
        var from = key.indexOf("[");
        if (from == -1) result[decodeURIComponent(key)] = val;
        else {
            var to = key.indexOf("]", from);
            var index = decodeURIComponent(key.substring(from + 1, to));
            key = decodeURIComponent(key.substring(0, from));
            if (!result[key]) result[key] = [];
            if (!index) result[key].push(val);
            else result[key][index] = val;
        }
    });
    return result;
}

/**
 * 
 * 
 * More documentation to come.
 */

export function shareAuthenticatedUserInfo(payload) {
    return { type: GET_AUTHENTICATED_USER, payload};
}

/**
 * 
 * 
 * More documentation to come.
 */

export function updateAuthenticatedUserInfo(payload) {
    return { type: UPDATE_AUTHENTICATED_USER, payload };
}

/**
 * 
 * 
 * More documentation to come.
 */

export function getUserOrgRepos(payload) {
    return { type: GET_REPOS, payload };
}

/**
 * 
 * 
 * More documentation to come.
 */

export function updateOrgRepos(payload) {
    return { type: UPDATE_REPOS, payload };
}

/**
 * This gets the Project, and gets the information to be displayed in the cards
 * More documentation to come.
 */

 //@TODO Get Recommended Repos here (This is where we set the Current Project)

async function setCurrentWorkspaceProjectProcessor (payload, dispatch, getState) {
    dispatch({ type: SET_CURRENT_PROJECT, payload });
    const { token } = getState();

    // Keeps track of the callbacks
    let colProcData = null;
    let linkedData = null;

    /**
     * This sets the initial set of columns in the workspace columns 
     * 
    */
    dispatch({ type: UPDATE_HOPPER_STATE, payload: [] });
    dispatch({ type: GET_PROJECT, payload: payload.columns.edges });
    dispatch({ type: HOPPER_LOADING_STATE, payload: true });
    // then we make a request to get additional set of columns

    //@TODO Give this a callback so that we can get Recommended Repos Correctly
    const colProcCallback = (err, data) => {
        
        console.log("--- shared worker callback ----")

        // Bug here
        // colProcData = data;
        // if (linkedData && colProcData) {
        //     initSharedWorker(dispatch, linkedData, colProcData);
        // } else {
        //     console.log("Waiting on Linked Data...");
        // }
    }

    ColumnProcessor(getState, payload, getState().currentProjectInfo, dispatch, colProcCallback);

    var params = {
        params: {
            projectId: payload.id
        }
    };
    // make a call to backend to link repos
    let getProjectRes = await axios.get(process.env.REACT_APP_AWS_LAMBDA_ENDPOINT + "/api/getProjectRepos", params)

    let linkedRepos = getProjectRes.data.Items;

    console.log(linkedRepos)
    //TODO: There should be only one object per id, update backend to do the same

    // This populates the Hopper Column with Project Data from Github
    if (linkedRepos[0] && linkedRepos[0].repoIds) {
        let repoIds = JSON.parse(linkedRepos[0].repoIds);

        var funcs = []

        for (const repo of repoIds) {
            var func = new Promise(function (resolve, reject) {
                Github.getRepoByID(token.eid, repo.databaseId)
                    .then((data) => {
                        resolve(data);
                    })
                    .catch((err) => {
                        reject("rejected");
                    })
                })
                .catch(function (err) {
                    console.error('err', err);
                });

            funcs.push(func)
        }

        Promise.all(funcs)
            .then((data) => {

                var arr = []

                data.forEach((repo) => {
                    if (repo) {
                        arr.push(repo)
                    }
                })

                payload.linkedRepos = [];
                payload.linkedRepos = arr;

                // Set the Linked Repo Data for starting the Recommended Repos Worker
                linkedData = arr;
                if (linkedData && colProcData) {
                    initSharedWorker(dispatch, linkedData, colProcData);
                } else {
                    console.log("Waiting on Col Proc Data...");
                }

                dispatch({ type: SET_CURRENT_PROJECT, payload });
                // dispatch({ type: HOPPER_LOADING_STATE, payload: false });
                
                // Callback to correctly set the Hopper Loading State
                const callback = (err, data) => {
                    dispatch({ type: HOPPER_LOADING_STATE, payload: false });
                }

                getIssuesAndPR(dispatch, getState, arr, token, callback) // This gets Data to put into colummns
            })

    } else {
        dispatch({ type: HOPPER_LOADING_STATE, payload: false });
        dispatch({ type: SET_CURRENT_PROJECT, payload });
    }



}

export function setCurrentWorkspaceProject(payload) {
    //TODO: Update this function when notifications are included. Idea being we will pull for new information if we dont have an update. Alterantively we will also provide user with manual update.

    return async function (dispatch, getState) {
        localStorage.setItem("krumboard-selected-project", JSON.stringify(payload))
        setCurrentWorkspaceProjectProcessor(payload, dispatch, getState)
    }

}


/**
 * This function gets in depth project detail (columns, card) from github for the repo selected.
 * More documentation to come.
 *
 */

export function getProjectInfoForRepo(payload) {
    return async function(dispatch, getState) {

        const { currentProject } = getState();

        const project = await API.graphql(graphqlOperation(GET_REPO_PROJECT_DETAIL, {
            org: currentProject.org
        }));
        dispatch({ type: CURRENT_REPO_PROECT_INFO, project });
    }
}

/**
 * This function gets information about current authticated user from github.
 * More documentation to come.
 * 
 */

export function getCurrentUserProfileFromGithub(payload) {
    return async function(dispatch) {
        const userInfo = await API.graphql(graphqlOperation(GET_CURRENT_USER));
        let payload = userInfo.data.viewer;
        return { type: GET_AUTHENTICATED_USER, payload };
    }
}

/**
 * This function to authenticate a user. 
 * More documentation to come.
 * 
 */

 //@TODO Update Recommended Repos here

export function loginUser() {
    return async function (dispatch, getState) {
        var payload = getJsonFromUrl(window.location);
        // When the app receives an auth redirect from github we take the token and save it to the local storage then refresh the app
        if (payload.eid) {
            localStorage.setItem("krumboard-auth-token", JSON.stringify(payload))
            window.location.href = "/";
            return;
        }


        payload = localStorage.getItem("krumboard-auth-token");
        if(payload) {
            payload = JSON.parse(payload);
        } else {
            // invalid auth
            console.log("Invalid auth")
            return
        }

        window.dispatchEvent(new CustomEvent("__ALLOW_AMPLIFY_GITHUB_CONNECTION__", {detail: payload}))

        // This action sets the token
        dispatch({ type: LOGIN_USER, payload });


        // At this point we should check what information we have saved. 
        // Information can be related to saved organization, selected repo. Purpose of this is to provide better UX. 
        
        // TODO: Find a way to share localstorage infromation across multiple devices.

        // Since the user is logged in at this point, we will go ahead and gets its basic credentials.

        try {
            const userInfo = await API.graphql(graphqlOperation(GET_CURRENT_USER));
            let userInfoPayload = userInfo.data.viewer;
            
            dispatch({ type: UPDATE_AUTHENTICATED_USER, payload: userInfoPayload });

            // If there is some information stored in local storage like last saved organization. Get the repos for those organizations here. If no organization is saved, show the organization page maybe?
            console.log("userInfoPayload", userInfoPayload)

            getLatestProjectsForOrg(dispatch, getState, userInfoPayload);

        } catch (err) {
            if(err.errors && err.errors.length > 0) {
                // propbably login failed
                // reload
                console.error(err)
                // localStorage.removeItem("krumboard-auth-token");
                // window.location.href = "/";

            }
        }
    }
}

async function getLatestProjectsForOrg(dispatch, getState, user) {
    // This is the query to get all the data

    // Fire a spinner

    // Lets get list of all project for all orgs

    let lastSavedProject = localStorage.getItem("krumboard-selected-project");

    if (user.organizations && user.organizations.edges) {
        var orgs = user.organizations.edges;

        var funcs = [];
        var orgsProjects = {};

        orgs.forEach( async (org) => {
            let b = await API.graphql(graphqlOperation(GET_PROJECTS_FOR_USER_QUERY))
            API.graphql(graphqlOperation(GET_PROJECTS_FOR_ORG_QUERY, { organization: org.node.login }))
            .then(function(projectInfo) {

                let projectInfoPayload = projectInfo.data.organization.projects;
                
                if (!orgsProjects[projectInfo.data.organization.login]) {
                    orgsProjects[projectInfo.data.organization.login] = {
                        creds: {
                            id: projectInfo.data.organization.id,
                            databaseId: projectInfo.data.organization.databaseId,
                            login: projectInfo.data.organization.login,
                            name: projectInfo.data.organization.name,
                            avatarUrl: projectInfo.data.organization.avatarUrl,
                        },
                        projects: []
                    }
                }

                // Add backlog and done columns 
                if (projectInfoPayload.nodes) {
                    projectInfoPayload.nodes.forEach((node) => {
                        node.columns.edges.unshift({
                            auto_generated: true,
                            // cursor: "backlog_" + uuid.v4(),
                            node: {
                                auto_generated: true,
                                id: "backlog_" + uuid.v4(),
                                name: "Hopper",
                                cards: {
                                    edges: []
                                }
                            }
                        })
                    })
                }

                orgsProjects[projectInfo.data.organization.login].projects = projectInfoPayload

                const ordered = {};
                Object.keys(orgsProjects).sort().forEach(function (key) {
                    ordered[key] = orgsProjects[key];
                });


                // Check whether this project is user's last selected project

                if (lastSavedProject) {
                    let lastSavedProjectObj = JSON.parse(lastSavedProject);
                    Object.keys(orgsProjects).forEach( (org) => {
                        let project = orgsProjects[org];

                        project.projects && project.projects.nodes && project.projects.nodes.forEach((node) => {
                            if (node.databaseId === lastSavedProjectObj.databaseId) {
                                node.org = lastSavedProjectObj.org;
                                setCurrentWorkspaceProjectProcessor(node, dispatch, getState)
                            }
                        })
                    })
                }

                console.log("Got Project:", ordered);

                dispatch({ type: ALL_PROJECTS_FOR_ALL_ORG, payload: ordered });

            })
            .catch(function (err) {
                console.error(err)
            })
            
        })

    } else {
        // User is not in any org
        console.warn("user does not belong to any org")
    }

    // Code for user projects
    // const userProjectInfo = await API.graphql(graphqlOperation(GET_PROJECTS_FOR_USER_QUERY));
    // projectInfoPayload.nodes = projectInfoPayload.nodes.concat(userProjectInfo.data.viewer.projects.nodes)
    // dispatch({ type: UPDATE_PROJECTS_FOR_ORG, payload: projectInfoPayload});

}


// utils
export function logOutFunc () {
    return async function () {
        localStorage.removeItem("krumboard-auth-token");
        window.location.href = "/";
    }
}

export function saveColumnState (key, state) {
    return async function (dispatch, getState) {

        // update column state with key and 
        const { columnStateCollections } = getState();
        if (!columnStateCollections[key]) {
            columnStateCollections[key] = []
        }

        columnStateCollections[key] = state;
        dispatch({ type: UPDATE_COLUMN_STATE, payload: columnStateCollections });

    }
}

// Mutations

export function moveProjectCard(payload) {
    return async function (dispatch, getState) {

        const { currentProjectInfo, token, currentProject, selectedFilter, search } = getState();
        var sourceCard = null;
        var destCard = null;
        var destCardMinus1 = null;
        var destColumn = null;

        // From hopper to one of the columns

        if ((payload.source.droppableId.indexOf("backlog_") >= 0) && (payload.destination.droppableId.indexOf("backlog_") !== 0)) {

            for (let i = 0; i < currentProjectInfo.length; i++) {
                let column = currentProjectInfo[i];
                if(column.node.id == payload.source.droppableId) {
                    sourceCard = FilterProcessor(JSON.parse(JSON.stringify(column.node.cards.edges)), selectedFilter, search)[payload.sourceIndex]
                }
            }

            currentProjectInfo.forEach((column) => {
                if (column.node.id == payload.destination.droppableId) {
                    destColumn = column
                }
            })

            Github.createProjectCard(token.eid, destColumn.node.databaseId, sourceCard.id, "Issue")
                .then((data) => {
                })
                .catch((err) => {
                    console.error(err)
                })

            // check here if the source card is the issue or pull request

        } 
        else if ((payload.destination.droppableId.indexOf("backlog_") >= 0) && (payload.source.droppableId.indexOf("backlog_") !== 0)) {

            // This means that the card is moving from one of the columns to the hopper, delete the card and update both hopper and the other column
            for (let i = 0; i < currentProjectInfo.length; i++) {
                let column = currentProjectInfo[i];
                if (column.node.id == payload.source.droppableId) {
                    sourceCard = FilterProcessor(JSON.parse(JSON.stringify(column.node.cards.edges)), selectedFilter, search)[payload.sourceIndex]
                }
            }

            Github.deleteProjectCard(token.eid, sourceCard.node.databaseId)
            .then((data) => {
                getIssuesAndPR(dispatch, getState, currentProject.linkedRepos || [], token)
            })
            .catch((err) => {
                console.error(err)
            })
        }
        else if ((payload.destination.droppableId.indexOf("backlog_") >= 0) && (payload.source.droppableId.indexOf("backlog_") >= 0)) {
            console.warn("Arranging of cards in the hopper is not allowed")
        }
        else {

            // check here if the card is coming from the hopper
            for (let i = 0; i < currentProjectInfo.length; i++) {
                let column = currentProjectInfo[i];
                if (column.node.id == payload.source.droppableId) {
                    sourceCard = FilterProcessor(JSON.parse(JSON.stringify(column.node.cards.edges)), selectedFilter, search)[payload.sourceIndex]
                }
            }
    
            currentProjectInfo.forEach((column) => {
                if (column.node.id == payload.destination.droppableId) {
                    if (payload.destIndex) {
                        destCardMinus1 = column.node.cards.edges[payload.destIndex - 1]
                    }
                }
            })

            const moveCard = await API.graphql(graphqlOperation(UpdateColumn, {
                cardId: sourceCard.node.id,
                columnId: payload.destination.droppableId,
                afterCardId: (destCardMinus1 && destCardMinus1.node && destCardMinus1.node.id) || null
            }));
    
            // dispatch({ type: CURRENT_REPO_PROECT_INFO, project });
        }


    }
}


export function addProjectColumn(payload) {
    return async function (dispatch, getState) {
        const { currentProject } = getState();

        const createColumnResponse = await API.graphql(graphqlOperation(AddProjectColumn, {
            projectId: currentProject.id,
            name: payload.name,
        }));

        window.dispatchEvent(new CustomEvent("show-toast", {
            detail: {
                type: "success",
                message: "Column created successfully!"
            }
        }))

        // dispatch({ type: CURRENT_REPO_PROECT_INFO, project });

    }
}

export function updateProjectColumn(name, column) {
    return async function (dispatch, getState) {
        const { currentProject } = getState();

        const updateColumnResponse = await API.graphql(graphqlOperation(UpdateProjectColumn, {
            projectColumnId: column.node.id,
            name: name,
        }));

        window.dispatchEvent(new CustomEvent("show-toast", {
            detail: {
                type: "success",
                message: "Column updated successfully!"
            }
        }))

        // dispatch({ type: CURRENT_REPO_PROECT_INFO, project });

    }
}

export function addProjectAndLinkRepos(payload, callback) {
    return async function(dispatch, getState) {

        const { currentProject, user, token } = getState();

        var org_id, user_id;

        if (user && user.organizations && user.organizations.edges) {
            user.organizations.edges.forEach( (edge) => {
                if (edge.node.login === payload.org) {
                    org_id = edge.node.id
                }
            })
        }

        const createProjectResponse = await API.graphql(graphqlOperation(AddProject, {
            ownerId: org_id || user_id,
            name: payload.name,
            body: payload.description ? payload.description : null
        }));

        payload.projectResponse = {}
        payload.projectResponse = createProjectResponse.data.createProject;


        // make a call to backend to link repos
        await axios.post(process.env.REACT_APP_AWS_LAMBDA_ENDPOINT + "/api/saveProjectRepos", payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token.eid
                }
            })

        if (callback) {
            callback()
        }
        window.dispatchEvent(new CustomEvent("show-toast", {
            detail: {
                type: "success",
                message: "Project created successfully!"
            }
        }))

        // make a call to get rest of the repos
        getLatestProjectsForOrg(dispatch, getState, user)

    }
}

export function removeLinkedRepo(payload, callback) {
    return async function(dispatch, getState) {



        const { currentProject, token, user } = getState();

        if (!currentProject || !currentProject.id) {
            console.log("No project selected");
            return;
        }

        var data = { params: { projectId: currentProject.id } };

        let getProjectRes = await axios.get(process.env.REACT_APP_AWS_LAMBDA_ENDPOINT + "/api/getProjectRepos", data);

        let linkedRepos = getProjectRes.data.Items;

        
        //TODO: There should be only one object per id, update backend to do the same
        
        if (linkedRepos[0] && linkedRepos[0].repoIds) {
            var repos = JSON.parse(linkedRepos[0].repoIds);

            var existingRepos = []
            payload.selectedRepos.forEach( (selectedRepo) => {
                repos.forEach( (repo) => {
                    if (repo.databaseId == selectedRepo.id) {
                        existingRepos.push(repo)
                    }
                })
            })

            let a = {
                id: linkedRepos[0].id,
                projectId: linkedRepos[0].projectId,
                selectedRepos: existingRepos
            }

            try {
                await axios.post(process.env.REACT_APP_AWS_LAMBDA_ENDPOINT + "/api/saveProjectRepos", a,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': token.eid
                        }
                    })

                if (callback) {
                    callback()
                }
                window.dispatchEvent(new CustomEvent("show-toast", {
                    detail: {
                        type: "success",
                        message: "Repo unlinked successfully!"
                    }
                }))

            } catch (err) {
                console.error(err)
            }

        }
        else {
            console.log("No linked repos")
        }
        
    }
}

//@TODO Use This to link a repo with the project
export function updateLinkedRepos(payload, callback) {
    return async function(dispatch, getState) {

        const { currentProject, token, user } = getState();

        if(!currentProject || !currentProject.id) {
            console.log("No project selected");
            return;
        }

        var params = { params: { projectId: currentProject.id } };

        let getProjectRes = await axios.get(process.env.REACT_APP_AWS_LAMBDA_ENDPOINT + "/api/getProjectRepos", params);

        let linkedRepos = getProjectRes.data.Items;

        //TODO: There should be only one object per id, update backend to do the same

        if (linkedRepos[0] && linkedRepos[0].repoIds) {

            let a = {
                id: linkedRepos[0].id,
                projectId: linkedRepos[0].projectId,
                selectedRepos: JSON.parse(linkedRepos[0].repoIds).concat(payload.selectedRepos)
            }

            try {
                let linkProjectResponse = await axios.post(process.env.REACT_APP_AWS_LAMBDA_ENDPOINT + "/api/saveProjectRepos", a,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': token.eid
                        }
                    })

                if (callback) {
                    callback()
                }
                window.dispatchEvent(new CustomEvent("show-toast", {
                    detail: {
                        type: "success",
                        message: "Repos linked successfully!"
                    }
                }))

            } catch (err) {
                console.error(err)
            }

        } else {
            // create new entry
            console.log("No existing repo found with this project, creating new one")

            let postPayload = {
                projectResponse: {},
                selectedRepos: payload.selectedRepos
            }

            postPayload.projectResponse = {
                project: currentProject,
            }

            // make a call to backend to link repos
            try {
                let linkProjectResponse = await axios.post(process.env.REACT_APP_AWS_LAMBDA_ENDPOINT + "/api/saveProjectRepos", postPayload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token.eid
                    }
                })
                console.log(linkProjectResponse)
                if (callback) {
                    callback()
                }
                window.dispatchEvent(new CustomEvent("show-toast", {
                    detail: {
                        type: "success",
                        message: "Repos linked successfully!"
                    }
                }))
    
                // make a call to get rest of the repos
                getLatestProjectsForOrg(dispatch, getState, user)
            } catch(err) {
                console.log("Error occured", err)
            }

        }


    }
}

export function getIssuesForRepo(payload, page, callback, per_page) {
    return function (dispatch, getState) {

        if(Object.keys(payload).length === 0) {
            return;
        }

        const { token } = getState();
        Github.getIssuesForRepo(token.eid, payload.owner.login, payload.name, page, per_page)
        .then((data) => {

            if(callback) {
                callback(null, data)
            }
        })
        .catch((err) => {
            console.error(err)
        })
    }
}

export function getIssueDetails(payload, callback, onlyMD) {
    return async function (dispatch, getState) {

        if(Object.keys(payload).length === 0) {
            return;
        }

        const { token } = getState();

        if(onlyMD) {
            let markdown = await Github.markdownToHTML(token.eid, payload.body)
            if (callback) {
                callback(null, markdown)
            }
            return;
        }

        Github.getIssueDetails(token.eid, payload.repository.owner.login, payload.repository.name, payload.number)
        .then(async (data) => {
            let markdown = await Github.markdownToHTML(token.eid, data.body)
            if(callback) {
                callback(null, markdown)
            }
        })
        .catch((err) => {
            console.error(err)
        })
    }
}

export function syncLabels(payload, callback) {
    return function(dispatch, getState) {
    }
}

// Filter functions

export function calculateFilterDataset() {
    return async function(dispatch, getState) {
        /**
         * Here we will calculate filter for the project selected
         */

        const { currentProject, currentProjectInfo, filterForProject, selectedFilter  } = getState();

        let calc_labels = [];
        let calc_assignees = [];
        let calc_repos = [];
        let calc_milestones = [];
        // Keep track of already added milestones
        let added_milestones = {};

        console.log("calculateFilterDataset", currentProjectInfo)

        currentProjectInfo.forEach((column) => {
            if (column && column.node.cards && column.node.cards.edges) {
                column.node.cards.edges.forEach((card) => {

                    if(card.id) {

                        let found = false;
                        let isClosed = card && card.state === "CLOSED" ? true : false;
                        let labels = card ? card.labels : [];
                        let assignees = card && card.assignees ? card.assignees : [];
                        let repositoryName = card && card.repository_url ? card.repository_url.substring(card.repository_url.lastIndexOf("/") + 1, card.repository_url.length) : '';
                        let milestone = card && card.milestone ||  {};

                        if(!isClosed) {
                            labels && labels.forEach((node) => {
                                if (calc_labels.indexOf(node.name) < 0) {
                                    calc_labels.push(node.name)
                                }
                            })

                            if (calc_repos.indexOf(repositoryName) < 0) {
                                calc_repos.push(repositoryName)
                            }

                            assignees && assignees.forEach((node) => {
                                if ((calc_assignees.indexOf(node.login) < 0) && (node.login)) {
                                    calc_assignees.push(node.login)
                                }
                            })

                            if (milestone && milestone.title && !added_milestones[milestone.title]) {
                                added_milestones[milestone.title] = milestone;
                                calc_milestones.push(milestone.title)
                            }                            
                        }


                    } else {
                        card = card.node;
                        let isClosed = card && card.content && card.content.state === "CLOSED" ? true : false;
                        let repositoryName = card && card.content ? card.content.repository.name : 'no repo';
                        let labels = card && card.content ? card.content.labels : { nodes: [] };
                        let assignees = card && card.content ? card.content.assignees : { nodes: [] };

                        let milestone = card && card.content ? card.content.milestone : {};

                        if (!isClosed) {
    
                            // calculate labels
                            labels && labels.nodes.forEach((node) => {
                                if (calc_labels.indexOf(node.name) < 0) {
                                    calc_labels.push(node.name)
                                }
                            })
    
                            // calculate repos
                            if (calc_repos.indexOf(repositoryName) < 0) {
                                calc_repos.push(repositoryName)
                            }
    
                            // calculate assignees
                            assignees && assignees.nodes.forEach((node) => {
                                if ((calc_assignees.indexOf(node.login) < 0) && (node.login)) {
                                    calc_assignees.push(node.login)
                                }
                            })

                            if (milestone && !added_milestones[milestone.title]) {
                                added_milestones[milestone.title] = milestone;
                                calc_milestones.push(milestone.title)
                            }
                        }
                    }
                })

            }

        })

        // Only add Unassigned option if there are assignees already in the list
        if (calc_assignees.length > 0) {
            calc_assignees.push("Unassigned");
        }

        // Sort the milestones by creation date
        // @TODO Make this more efficient
        let tempMilestones = [];
        calc_milestones.forEach((item) => {
            tempMilestones.push(added_milestones[item]);
        });
        tempMilestones.sort((a, b) => moment(a.created_at).diff(moment(b.created_at)));
        calc_milestones = [];
        tempMilestones.forEach((item) => {
            calc_milestones.push(item.title);
        });
        
        dispatch({ type: UPDATE_FILTER, payload: {
            type: ["Issue", "PullRequest"],
            labels: calc_labels.sort(function (a, b) {return a.toLowerCase().localeCompare(b.toLowerCase());}),
            assignees: calc_assignees.sort(function (a, b) {return a.toLowerCase().localeCompare(b.toLowerCase());}),
            repositories: calc_repos.sort(function (a, b) {return a.toLowerCase().localeCompare(b.toLowerCase());}),
            milestones: calc_milestones
        } });

    }
}

export function updateSelectedFilter(filter) {
    return async function(dispatch) {
        dispatch({ type: SELECTED_FILTER, payload: filter});
    }
}

export function searchProject(search) {
    return async function (dispatch) {
        dispatch({ type: SEARCH_PROJECT, payload: {value: search} });
    }
}

export function updateRecommendedRepos(recRepos) {
    return async function(dispatch) {
        dispatch({type: RECOMMENDED_REPOS, payload: recRepos});
    }
}

// Initializes and fires the Shared Worker
function initSharedWorker(dispatch, linkedData, currentProject) {
    dispatch({ type: RECOMMENDED_REPOS, payload: []});
    //@TODO Verify that this path won't break things
    const sharedWorker = recommendedRepos();
    sharedWorker.getRecommendedRepos(linkedData, currentProject).then((data) => {
        dispatch({ type: RECOMMENDED_REPOS, payload: data});
    });
    // sharedWorker.addEventListener('message', (e) => {
    //     
    // });
}