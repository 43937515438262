import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

export const NormalTextField = styled(TextField)`
    && {

        min-width: 250px;
        margin-left: ${props => props.custommargin ? "6px" : "0"};
        margin-right: ${props => props.custommargin ? "6px" : "0"};


        // we dont want to highlight our labels
        & > label {
            line-height: 1.33;
            color: var(--default-label-color);
        }

        // This styling will override default hover color of material ui textfield
        & > div:hover:before {
            border-bottom: 1px solid var(--default-label-color) !important;
        }
    }
`;

export const RequiredTextField = styled(TextField)`
    && {

        min-width: 250px;
        margin-left: ${props => props.custommargin ? "6px" : "0"};
        margin-right: ${props => props.custommargin ? "6px" : "0"};

        // we dont want to highlight our labels
        & > label {
            line-height: 1.33;
            color: ${props => props.error ? "var(--required-textfield-color)" : "var(--default-label-color)"};
        }

        // This styling will override default hover color of material ui textfield
        & > div:hover:before {
            border-bottom: 1px solid var(--default-label-color) !important;
        }

        & > label::after {
            content: "●";
            width: 6px;
            height: 6px;
            margin-left: 2px;
            color: var(--required-textfield-color);
        }
    }
`;