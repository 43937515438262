// All styles are referenced from https://app.zeplin.io/project/594c3653f67172e69b3cb0f7/styleguide/components

// Mockup https://app.zeplin.io/project/594c3653f67172e69b3cb0f7/styleguide/components

import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const PrimaryButton = styled(Button)`
    && {
        height: 40px;
        border-radius: 2px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
        background-color: var(--primary-button-color);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: var(--primary-button-text-color);
        max-width: 288px;
        width: ${props => props.setwidth ? "256px" : undefined};


        :hover {
            background-color: var(--primary-button-color);
        }

        :active {
            background-color: var(--primary-button-color);
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            max-width: 100%;
        }

    }
`;

export const DeleteButton = styled(Button)`
    && {
        height: 40px;
        border-radius: 2px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
        background-color: var(--danger-button-color);
        color: var(--danger-button-text-color);
        max-width: 288px;
        width: ${props => props.setwidth ? "256px" : undefined};


        :hover {
            background-color: var(--danger-button-hover-color);
        }

        :active {
            background-color: var(--danger-button-active-color);
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            max-width: 100%;
        }

    }
`;

export const SecondaryButton = styled(Button)`
    && {
        height: 40px;
        border: 1px solid;
        border-radius: 2px;
        border-color: var(--app-user-repos-background-color);
        background-color: transparent;
        color: var(--app-user-repos-background-color);
        max-width: 288px;
        width: ${props => props.setwidth ? "256px" : undefined};


        :hover {
            background-color: transparent;
        }

        :active {
            background-color: transparent;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            max-width: 100%;
        }

    }
`;

export const GreenButton = styled(Button)`
    && {
        height: 40px;
        border-radius: 2px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
        background-color: var(--green-button-color);
        color: var(--green-button-text-color);
        max-width: 288px;
        width: ${props => props.setwidth ? "256px" : undefined};


        :hover {
            background-color: var(--green-button-hover-color);
        }

        :active {
            background-color: var(--green-button-active-color);
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            max-width: 100%;
        }

    }
`;

export const HeaderButton = styled(Button)`
    && {
        color: ${props => props.defaultcolor ? "inherit" : "rgba(255, 255, 255, 0.87)"};
		margin: 0;
        min-width: 0;
        font-weight: bold;
        margin-right: 16px;
        letter-spacing: 0.5px;
    }
`

export const TransparentButton = styled(Button)`
    && {
        color: var(--primary-text-color);
		margin: 0;
        min-width: 0;
        font-weight: ${props => props.override ? "normal" : "bold"};
        margin-right: 16px;
        font-size: 12px;
        letter-spacing: ${props => props.override ? "normal" : "0.5px"};

        :hover {
            background-color: transparent;
        }
    }
`

export const ClearButton = styled(Button)`
    && {
        color: #29a9e2;
        font-weight: bold;
    }
` 	