// Reducers produce the state of the application.
// Reducers know when to change state is by sending a signal to the store. 
// The signal is an action. "Dispatching an action" is the process of sending out a signal.

// Important thing to note is you dont change an existing state. You make a copy of that state with current plus new data.

import { 

    GET_AUTHENTICATED_USER, 
    UPDATE_AUTHENTICATED_USER, 
    LOGIN_USER,
    GET_REPOS,
    SET_CURRENT_PROJECT,
    UPDATE_REPOS,
    CURRENT_REPO_PROECT_INFO,
    UPDATE_PROJECTS_FOR_ORG,
    UPDATE_COLUMN_STATE,
    UPDATE_HOPPER_STATE,
    FILTER_FOR_PROJECT,
    SELECTED_FILTER,
    UPDATE_FILTER,
    SEARCH_PROJECT,
    ALL_PROJECTS_FOR_ALL_ORG,
    GET_PROJECT,
    HOPPER_LOADING_STATE,
    RECOMMENDED_REPOS

} from "../constants/action-types";

const initialState = {
    user: {},
    repos: [],
    currentRepo: {},
    currentRepoProjectInfo: {},
    currentProjectsForOrg: {},
    currentProject: {},
    currentProjectInfo: [],
    columnStateCollections: {},
    hopperIssues: [],
    filterForProject: {},
    selectedFilter: {},
    orgsProjects: {},
    filter: {},
    search: {},
    token: {},
};

function rootReducer(state = initialState, action) {

    if (action.type === GET_AUTHENTICATED_USER) {
        // This is wrong because it break state immutability rule of redux
        // state.user.push(action.payload)

        // To fix  the above rule we create a "copy" of the state
        return state.user
    }
    else if (action.type === UPDATE_AUTHENTICATED_USER) {
        return Object.assign({}, state, {
            user: action.payload
        })
    }
    else if (action.type === LOGIN_USER) {
        return Object.assign({}, state, {
            token: action.payload
        })
    }
    else if (action.type === UPDATE_REPOS) {
        return Object.assign({}, state, {
            repos: action.payload
        })
    }
    else if (action.type === ALL_PROJECTS_FOR_ALL_ORG) {
        return Object.assign({}, state, {
            orgsProjects: action.payload
        })
    }
    else if (action.type === SET_CURRENT_PROJECT) {
        return Object.assign({}, state, {
            currentProject: action.payload
        })
    }
    else if (action.type === CURRENT_REPO_PROECT_INFO) {
        return Object.assign({}, state, {
            currentRepoProjectInfo: action.payload
        })
    }
    else if (action.type === UPDATE_PROJECTS_FOR_ORG) {
        return Object.assign({}, state, {
            currentProjectsForOrg: action.payload
        })
    }
    else if (action.type === UPDATE_COLUMN_STATE) {
        return Object.assign({}, state, {
            columnStateCollections: action.payload
        })
    }
    else if (action.type === UPDATE_HOPPER_STATE) {
        return Object.assign({}, state, {
            hopperIssues: action.payload
        })
    }
    else if (action.type === HOPPER_LOADING_STATE) {
        return Object.assign({}, state, {
            hopperLoadingState: action.payload
        })
    }
    else if (action.type === RECOMMENDED_REPOS) {
        return Object.assign({}, state, JSON.parse(JSON.stringify({
            recommendedRepos: action.payload
        })));
    }
    else if (action.type === FILTER_FOR_PROJECT) {
        return Object.assign({}, state, {
            filterForProject: action.payload
        })
    }
    else if (action.type === SELECTED_FILTER) {
        return Object.assign({}, state, JSON.parse(JSON.stringify({
            selectedFilter: action.payload
        })))
    }
    else if (action.type === SEARCH_PROJECT) {
        return Object.assign({}, state, JSON.parse(JSON.stringify({
            search: action.payload
        })))
    }
    else if (action.type === UPDATE_FILTER) {
        return Object.assign({}, state, JSON.parse(JSON.stringify({
            filter: action.payload
        })))
    }
    else if(action.type === GET_PROJECT) {
        // return { ...state, currentProjectInfo: action.payload };
        // TODO: Find out why the view is not being rendered even though the update is being fired
        // return Object.assign(state.currentProjectInfo, state, {
        //     currentProjectInfo: action.payload
        // })
        action.payload[0].node.cards.edges = state.hopperIssues;
        return Object.assign({}, state, JSON.parse(JSON.stringify({
            currentProjectInfo: action.payload
        })))
    }

    return state;
}

export default rootReducer;