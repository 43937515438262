import React from 'react';
import { connect } from "react-redux";
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MdClear } from 'react-icons/md';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Local components
import { HeaderComponent } from "./HeaderComponent";

import "../../styles/common.css";
import "../../styles/common.right-panel.css";
import "../../styles/common.github.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";

import { setCurrentWorkspaceProject, getIssueDetails } from "../../redux/actions/index";

const styles = {
    RightSideDrawer: {
        backgroundColor: "var(--app-workspace-background-color)",
    },

};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentProject: repo => dispatch(setCurrentWorkspaceProject(repo)),
        getIssueDetails: (payload, callback, onlyMD) => dispatch(getIssueDetails(payload, callback, onlyMD)),
    }
}

class RightSidePanel extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: [],
            right: false, 
            issue: {},
            value: 0
        };

        this.drawerRoot = React.createRef();
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleDrawerViaEvent = this.toggleDrawerViaEvent.bind(this);

        window.addEventListener("open-right-side-drawer", this.toggleDrawerViaEvent);
    }

    componentWillUnmount() {
        window.removeEventListener("open-right-side-drawer", this.toggleDrawerViaEvent);
    }

    componentDidMount() {}

    toggleDrawerViaEvent (event) {

        if(event.detail.content) {
            this.setState({
                issue: event.detail.content
            })
            this.props.getIssueDetails(event.detail.content, this.setPanelState);
        } else {
            this.setState({
                issue: event.detail
            })
            
            this.props.getIssueDetails(event.detail, this.setPanelState, true);
        }
        this.toggleDrawer('right', true)();
        
    }
    
    setPanelState = (err, data) => {

        if(!data) {
            data = "<div class='layout vertical center vertical-center'>No description found</div>"
        }
        if (this.state.issue) {
            this.state.issue.bodyHTML = data;
            this.setState({
                issue: this.state.issue
            })
        }
    }

    toggleDrawer = (side, open) => () => {
        document.querySelector("#appHome").style.width = open ? "calc(100% - 588px)" : "100%"
        this.setState({
            [side]: open,
        });
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {

        const { classes } = this.props;
        // {...(condition ? { bsStyle: 'success' } : {}) }
        return (
            <Drawer
                {...(this.state.right ? { variant: 'permanent' } : {})}
                ModalProps={{
                    BackdropProps: {
                        invisible: true
                    }
                }} 
                id="rightPanel"
                PaperProps={{
                    elevation: 1,
                }} 
                classes={{ paper: 'right-panel' }} 
                anchor="right" open={this.state.right} 
                onClose={this.toggleDrawer('right', false)}
            >
                <div tabIndex={0} ref={this.drawerRoot} id="drawerRoot" className={classes.RightSideDrawer + " layout vertical full-height right-panel"} role="button">



                    <HeaderComponent
                        icon={<MdClear className="icon" onClick={this.toggleDrawer('right', false)}/>}
                        title={<span className="truncate">{this.state.issue && this.state.issue.title }</span>}
                    />
                    <div className="flex full-height  container-overflow-y panel-container">



                        <div className="github-html-container">
                            {ReactHtmlParser(this.state.issue && this.state.issue.bodyHTML)} 
                        </div>
                    </div>

                    {/* <Tabs value={this.state.value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleChange} centered>
                        <Tab label="Issue details" />
                        <Tab label="Todos" />
                    </Tabs> */}
                    {/* <div style={{ position: "fixed", bottom: "0", width: "100%" }}>
                    </div> */}

                </div>
            </Drawer>
        )
    }
}

RightSidePanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(RightSidePanel));
// export default withStyles(styles)(RightSidePanel);
// export default RightSidePanel;
