import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/index"
import thunk from "redux-thunk";
import reduxWebsocket from 'react-redux-websocket';

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const socket = new WebSocket('wss://qik5jbcfv6.execute-api.us-east-1.amazonaws.com/dev');

// createStore takes a reducer as the first argument

const store = createStore(
    rootReducer,
    storeEnhancers(applyMiddleware(thunk))
);
export default store;