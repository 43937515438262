import React from 'react';

import "../../styles/common.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";

// Single title header component

/**
 * Usage
 *                 
 * <HeaderComponent
        icon={<MdClear className="icon" />}
        title={<span>Subheader title</span>}
        suffix={<MdMoreVert className="icon" />}
    />
 */

class HeaderComponent extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    render() {

        // The properties should be defined as attribute during the defination of this component

        const { icon, title, suffix } = this.props;

        return (
            <div className="layout horizontal center header-component-container">
                {
                    icon ? 
                    <div className="icon-container">
                        {icon}
                    </div> : ""
                }
                <div className="layout horizontal center truncate flex title-container">
                    {title}
                </div>
                <div className="icon-container">
                    {suffix}
                </div>
            </div>
        )
    }
}

// Dual title header component
/**
 * Usage
 *                 
 * <DualTitleHeaderComponent
        icon={<MdClear className="icon" />}
        titleBig={<span>Title Big</span>}
        titleSmall={<span>Title Small</span>}
        suffix={<MdMoreVert className="icon" />}
    />
 */

class DualTitleHeaderComponent extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    render() {

        const { icon, titleBig, titleSmall, suffix } = this.props;

        return (
            <div className="layout horizontal center app-header">
                <div className="icon-container">
                    {icon}
                </div>

                <div className="layout vertical flex truncate">
                    <div className="layout horizontal truncate title-big">
                        {titleBig}
                    </div>
                    <div className="layout horizontal truncate title-small">
                        {titleSmall}
                    </div>
                </div>

                <div className="icon-container">
                    {suffix}
                </div>
            </div>
        )
    }
}

export { HeaderComponent, DualTitleHeaderComponent };
