import React from 'react';
import CustomProperties from 'react-custom-properties';

// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from "react-redux";
import Websocket from 'react-websocket';

// js
import Home from "./components/layout/Home";
import { PrimaryButton } from "./components/styled-components/Buttons/Buttons";
import CustomToast from "./components/utils/Toast";

//  Redux imports
import { loginUser, } from "./redux/actions/index";
import { updateColumnsFromWSEvent } from "./redux/other/Subscriptions";

// css
import "./styles/common.css";
import "./styles/common.login.css";
import "./styles/iron-flex-layout.css";
import "./styles/iron-flex-layout-classes.css";


const mapStateToProps = state => {
  return { 
    token: state.token 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: () => dispatch(loginUser()),
    updateColumnsFromWSEvent: (payload) => dispatch(updateColumnsFromWSEvent(payload)),
  }
}

class App extends React.Component {

  state = {
    token: {},
    cssProperties: {
        '--app-user-repos-background-color':  "#176080",
        '--app-workspace-background-color': "#e1f1f8",
        '--primary-icon-color': "white",
        '--secondary-icon-color': "rgba(0, 0, 0, 0.8)",
        '--workspace-column-card-color': "#f2f6f8",
        '--primary-text-color': "#176080",
        '--secondary-text-color': "#ffffff",
        '--default-label-color': "rgba(0, 0, 0, 0.60)",
        '--card-body-color': "#176080",
        '--primary-button-color': "#29a9e2",
        '--primary-button-text-color': 'white',
    },
    defaultCssProperties: {
        '--app-user-repos-background-color': "#176080",
        '--app-workspace-background-color': "#e1f1f8",
        '--primary-icon-color': "white",
        '--secondary-icon-color': "rgba(0, 0, 0, 0.8)",
        '--workspace-column-card-color': "#f2f6f8",
        '--primary-text-color': "#176080",
        '--secondary-text-color': "#ffffff",
        '--default-label-color': "rgba(0, 0, 0, 0.60)",
        '--card-body-color': "#176080",
        '--primary-button-color': "#29a9e2",
        '--primary-button-text-color': 'white',
    }    
  }

  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    this.props.login();

    window.addEventListener("theme-change", (event) => {
        Object.keys(event.detail).forEach((key) => {
            this.state.cssProperties[key] = event.detail[key]
            this.setState({
                cssProperties: this.state.cssProperties
            })
        })
    })
  }

  handleData (data) {
    console.log("INside handle data", JSON.parse(data))
    data = JSON.parse(data)
    this.props.updateColumnsFromWSEvent(data);

  }

    render() {
        const { cssProperties } = this.state;

        return (
            <>

                <CustomProperties global properties={{

                    '--app-user-repos-background-color': cssProperties['--app-user-repos-background-color'],
                    '--app-workspace-background-color': cssProperties['--app-workspace-background-color'],
                    '--primary-icon-color': cssProperties['--primary-icon-color'],
                    '--secondary-icon-color': cssProperties['--secondary-icon-color'],
                    '--workspace-column-card-color': cssProperties['--workspace-column-card-color'],
                    '--primary-text-color': cssProperties['--primary-text-color'],
                    '--secondary-text-color': cssProperties['--secondary-text-color'],
                    '--default-label-color': cssProperties['--default-label-color'],
                    '--card-body-color': cssProperties['--card-body-color'],
                    '--primary-button-color': cssProperties['--primary-button-color'],
                    '--primary-button-text-color': cssProperties['--primary-button-text-color'],

                }} />
                {
                this.props.token.eid ?
                    <>
                    <Websocket url='wss://qik5jbcfv6.execute-api.us-east-1.amazonaws.com/dev'
                        onMessage={this.handleData.bind(this)} />
                    <Home />
                    </>
                    : 

                    <div className="layout vertical full-height center flex vertical-center app-background">

                        <div className="layout vertical logincontainer">

                            <div className="flex">
                                <div className="app-logo-container">
                                        <img sizing="contain" className="app-logo" src={process.env.PUBLIC_URL + '/images/krum-gear5.svg'} />
                                </div>

                                    <span className="layout horizontal center-justified title-bold-24">Krumboard</span>
                                <span className="layout horizontal center-justified title-16 text-center">a visualization board for <br /> GitHub</span>
                            </div>

                            <div className="layout horizontal center-justified margin-16">
                                <PrimaryButton setwidth="true"> 
                                        <a href={process.env.REACT_APP_AWS_LAMBDA_ENDPOINT + "/auth/github?callback_url=" + window.location.href}>Continue with Github </a>
                                </PrimaryButton>
                            </div>

                            <div className="height-20"></div>

                        </div>
                        <div className="height-10"></div>
                        <div className="login-subtext">from the monsters at <a target="_blank" href="https://www.krum.io" className="txt-bold krum-link">Krumware</a></div>
                        <div className="height-10"></div>
                        <div className="login-subtext"> Pre-alpha release. Available only for KrumIO users</div>
                    </div>
                }

                <CustomToast />
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
