export const SEARCH_REPO = ` 
    query SearchRepo($queryString: String!) {
        search(query: $queryString, type: REPOSITORY, first: 50) {
            edges {
                node {
                    ... on Repository {
                        id
                        databaseId
                        name
                    }
                }
                cursor
            }
        }
    }

`;

export const SEARCH_LABELS_WITHIN_REPO = ` 
    query GetLabelsForRepo($owner: String!, $name: String!) {
        repository(owner: $owner, name: $name) {
            labels(first: 100) {
                nodes {
                    name
                    color
                }
            }
        }
    }

`;