export const GET_PROJECTS_FOR_ORG_QUERY = `

    query GetProjectFromOrg($organization: String!) {
        organization(login: $organization) {
            projectsUrl
            name
            id
            databaseId
            login
            avatarUrl
            viewerCanAdminister
            viewerCanCreateProjects
            projects(first: 50) {
                totalCount

                nodes {
                    databaseId
                    number
                    name
                    id
                    url
                    viewerCanUpdate
                    columns(first: 10) {
                        totalCount
                        edges {
                            cursor
                            node {
                                databaseId
                                id
                                name
                                cards(first: 1) {
                                    totalCount
                                }
                            }
                        }
                    }                       
                }
            }
            description
        }
    }
    
    
`