import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import '../../styles/common.card.css';
import CardComponent from "./CardComponent";


const getDraggableStyle = (draggableSnapshot, draggableStyle) => {
    // rest mode
    let cursor = 'pointer';

    if (draggableSnapshot.isDragging) {
        cursor = 'grabbing';
    }
    if (draggableSnapshot.isDragging && !draggableSnapshot.draggingOver) {
        cursor = 'not-allowed';
    }
    return {
        ...draggableStyle,
        cursor: cursor,
        pointerEvents: 'auto',
    };
};

class Card extends Component {

    state = {
        smallScreen: false
    }

    constructor(props) {
        super(props);
    }

    openCardDetails (event, card, snapshot) {
        if(snapshot && !snapshot.isDragging) window.dispatchEvent(new CustomEvent("open-right-side-drawer", {detail: card}))
    }


  render() {

    let card = this.props.card || {};

    return (
        <>
        {
                card && card.content && card.content.state === "CLOSED" ? 
                    <Draggable isDragDisabled={this.props.smallScreen} key={this.props.identifier} draggableId={this.props.identifier} index={this.props.index}>
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            </div>
                        )}
                    </Draggable>
                : 
                <Draggable isDragDisabled={this.props.smallScreen} key={this.props.identifier} draggableId={this.props.identifier} index={this.props.index}>
                    {/* className={`Card ` + card && card.content && card.content.state === "CLOSED" ? "hide-card" : ""} */}
                    {(provided, snapshot) => (
                        <div 
                        ref={provided.innerRef} 
                        {...provided.draggableProps} 
                        {...provided.dragHandleProps} 
                        style={getDraggableStyle(snapshot, provided.draggableProps.style)}

                        className="Card"  
                        onClick={(e) => this.openCardDetails(e, card, snapshot)}>
                            {/* {cardComponent} */}
                                <CardComponent card={card} restApi={this.props.restApi} />
                        </div>
                    )}
                </Draggable>
        }
        </>
      )
    }
};

export default Card;