import React from 'react';
import { HeaderComponent } from "./HeaderComponent";
import { MdClear, MdAdd } from 'react-icons/md';
import IconButton from '@material-ui/core/IconButton';
import { connect } from "react-redux";
// import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Badge from '@material-ui/core/Badge';
import { Link } from "react-router-dom";
import { NormalTextField } from "../styled-components/TextFields/TextFields";
import { SecondaryButton, HeaderButton } from "../styled-components/Buttons/Buttons";
import { searchRepo, getLabelsForRepo } from "../../redux/processors/SearchProcessor";
import { syncLabels } from "../../redux/actions/index";
import { removeLinkedRepo, updateLinkedRepos, updateRecommendedRepos } from "../../redux/actions/index";

import "../../styles/common.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";
// Dialog Import
import Dialog from '@material-ui/core/Dialog';
import AddProjectDialogContent from "./AddProjectDialogContent";


const mapDispatchToProps = (dispatch) => {
    return {
        searchRepo: (queryString, callback) => dispatch(searchRepo(queryString, callback)),
        getLabelsForRepo: (name, owner, callback) => dispatch(getLabelsForRepo(name, owner, callback)),
        syncLabels: (payload, callback) => dispatch(syncLabels(payload, callback)),
        removeLinkedRepo: (payload, callback) => dispatch(removeLinkedRepo(payload, callback)),
        updateLinkedRepos: (payload, callback) => dispatch(updateLinkedRepos(payload, callback)),
        updateRecommendedRepos: (payload, callback) => dispatch(updateRecommendedRepos(payload, callback))
    };
};

const mapStateToProps = state => {
    console.log("In Project Settings:", state);
    return {
        currentProject: state.currentProject,
        repos: [],
        transferFromLabels: [],
        recommendedRepos: state.recommendedRepos || []
    };
};



class ProjectSettings extends React.Component {

    state = {
        smallScreen: false,
        currentProject: this.props.currentProject || {},
        openDialog: false, 
        transferFromRepo: {},
        transferToRepo: {},
        transferFromInputValue: "",
        selectedRepos: (this.props.currentProject && this.props.currentProject.linkedRepos) || [],
        projectName: this.props.currentProject && this.props.currentProject.name,
        selectedKey: 'projectDetails',
        items: [
            {
                key: "projectDetails",
                title: "Project Details",
            },
            {
                key: "manageRepos",
                title: "Manage Linked Repos",
                badge: this.props.recommendedRepos && this.props.recommendedRepos.length > 0
            },
            // {
            //     key: "manageUsers",
            //     title: "Manage Users",
            // },
            // {
            //     key: "syncLabels",
            //     title: "Sync Labels",
            // },
            // {
            //     key: "advancedSettings",
            //     title: "Advanced Settings",
            // },
        ],

    }

    constructor(props, context) {
        super(props, context);
        this.settingsItemContainerRef = React.createRef();
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    itemClick = (event, item) => {
        this.state.selectedKey = item.key;
        
        this.setState({
            selectedKey: this.state.selectedKey
        })

        // const node = this.settingsItemContainerRef.current;

        // Spread operator is used to convert HTMLCollection to array
        // node.children && [...node.children].forEach( (child) => {
        //     if(child === event.currentTarget) {
        //         child.setAttribute("active", true)
        //     } else {
        //         child.removeAttribute("active")
        //     }
        // })

    }

    transferFromSelectClick = (str, callback) => {
        this.state.transferFromInputValue = str;
        if(!str) {
            return []
        }

        let queryString = str + " org:" + (this.state.currentProject && this.state.currentProject.org);
        this.props.searchRepo(queryString, (res) => {
            var arr = []
            res.forEach((repo) => {
                arr.push({
                    value: repo.node.databaseId,
                    org: this.state.currentProject.org,
                    label: repo.node.name,
                })
            })

            callback(arr)
        })

    }

    selectTransferFromRepo = (e) => {
        this.state.transferFromRepo = e;
        // this.props.getLabelsForRepo(e.label, e.org, (res) => {
        //     console.log(res)
        //     this.state.transferFromLabels = res;
        // })
    }

    transferToSelectClick = (str, callback) => {
        if (!str) {
            return []
        }

        let queryString = str + " org:" + (this.state.currentProject && this.state.currentProject.org);
        this.props.searchRepo(queryString, (res) => {
            var arr = []
            res.forEach((repo) => {
                arr.push({
                    value: repo.node.databaseId,
                    org: this.state.currentProject.org,
                    label: repo.node.name,
                })
            })

            callback(arr)
        })

    }

    selectTransferToRepo = (e) => {
        this.state.transferToRepo = e;
    }

    removeLinkedProject = (repo)  => {
        console.log("Remove linked project", repo, this.state.currentProject)
        this.state.currentProject.linkedRepos = this.state.currentProject.linkedRepos.filter( (item) => item.id !== repo.id)
        console.log(this.state.currentProject.linkedRepos)
        this.setState({
            selectedRepos: this.state.currentProject.linkedRepos
        })
        this.props.removeLinkedRepo({
            selectedRepos: this.state.currentProject.linkedRepos
        }, ( e ) => {
            console.log("updatedLinkedRepos called")
        });
    }

    linkToProject = (repo) => {
        if (!this.state.currentProject.linkedRepos) return; // @TODO When the issue where starting on the settings page doesn't properly populate the currentProject is fixed, then remove this
        console.log("Adding Repo to Project...", repo);

        //@TODO Finish this!
        this.state.currentProject.linkedRepos.push(repo);
        this.setState({selectedRepos: this.state.currentProject.linkedRepos});
        this.props.updateLinkedRepos({
            selectedRepos: this.state.currentProject.linkedRepos
        }, ( e ) => {
            console.log("updatedLinkedRepos called")
        });
        let idx = this.getIndexOf(this.props.recommendedRepos, repo.databaseId);
        if (idx >= 0) {
            this.props.recommendedRepos.splice(idx, 1);
            this.props.updateRecommendedRepos(this.props.recommendedRepos);
        }
    }

    getIndexOf = (recRepos, dbid) => {
        if (recRepos && recRepos.length) {
            for (let i = 0; i < recRepos.length; i++) {
                if (recRepos[i].databaseId === dbid) {
                    return i;
                }
            }
        }
        return -1;
    }

    syncLabels = (event) => {
        this.props.syncLabels({
            transferFromRepo: this.state.transferFromRepo,
            transferToRepo: this.state.transferToRepo,
        }, (err, res) => {

        })
    }

    addProjectDialog = (org) => {
        this.setState({ "openDialog": true });
    };

    handleClose = (variable, value) => {
        this.setState({ [variable]: value });
    };

    render() {


        const ProjectDetailsFunc = () => {
            return (
                <>
                    <div className="flex">
                        <div className="layout horizontal center">
                            <span className="flex" style={{ fontSize: "24px"}}> 
                            Details </span>
                            <HeaderButton defaultcolor="true">Save</HeaderButton>
                        </div>
                        <div className="height-20"></div>
                        <div className="layout horizontal add-cards-search-container">
                            <NormalTextField label="Project name" placeholder="Enter project name" className="flex" value={this.state.projectName} onChange={this.handleChange('projectName')} margin="normal" InputLabelProps={{ shrink: true, }} />
                        </div>
    
                        <div className="layout horizontal">
                            <NormalTextField id="projectDesc" multiline label="Project description" placeholder="Enter description" className="flex" value={this.state.projectDesc} onChange={this.handleChange('projectDesc')} margin="normal" InputLabelProps={{ shrink: true, }} />
                        </div>
                    
                        <div className="height-20"></div>

                    </div>
    
                    <div className="layout horizontal">
                        <div className="flex"></div>
                        <SecondaryButton>Delete Project</SecondaryButton>
                    </div>            
                </>
            )
        }



        const SyncLabelsFunc = () => {
            return (
                <>
                    <div className="flex">
                        <div className="layout horizontal center">
                            <span className="flex" style={{ fontSize: "24px" }}>
                                Sync Labels </span>
                            <HeaderButton onClick={ (e) => this.syncLabels(e)} defaultcolor="true">Sync</HeaderButton>
                        </div>
                        <div className="height-20"></div>
                        <AsyncSelect
                            cacheOptions
                            isClearable={true}
                            placeholder="Transfer labels from"
                            onSelectResetsInput={false}
                            loadOptions={this.transferFromSelectClick}
                            onChange={this.selectTransferFromRepo}
                        />

                        {/* <div className="height-10"></div>

                        <span className="login-subtext"> {(this.state.transferFromLabels && this.state.transferFromLabels.length) || 0} labels found</span> */}

                        <div className="height-20"></div>
                        <AsyncSelect
                            cacheOptions
                            isClearable={true}
                            placeholder="Transfer labels to"
                            loadOptions={this.transferToSelectClick}
                            onChange={this.selectTransferToRepo}
                        />

                    </div>

                </>
            )
        }
        
        const ManageReposFunc = () => {
            return (
                <>
                    <div className="flex">
                        <div className="layout horizontal center">
                            <span className="flex" style={{ fontSize: "24px" }}>
                                Manage Linked Repos </span>
                        </div>
                        <div className="height-20"></div>

                        <div className="layout horizontal wrap">
                            {
                                this.state.selectedRepos.map((repo) => {
                                    if (repo.node) {
                                        return ("")
                                    } else {
                                        return (
                                            <div key={repo.id} className="layout horizontal center selected-repos-container">
                                                <span className="flex">{repo.name}</span>
                                                <div className="spacer-8"></div>
                                                <MdClear onClick={() => this.removeLinkedProject(repo)} className="icon-1 cursor-pointer" />
                                            </div>
                                        )
                                    }
                                })
                            }

                            <div onClick={() => this.addProjectDialog()} className="layout horizontal center selected-repos-add-container cursor-pointer">
                                <span className="flex">Add more</span>
                                <div className="spacer-8"></div>
                                <MdAdd className="icon-1" />
                            </div>
                        </div>

                        <div className="height-20"></div>

                        {/* <div className="layout horizontal center">
                            <span className="flex" style={{ fontSize: "24px" }}>
                                Suggested Repos </span>
                        </div>

                        <div className="layout horizontal wrap">
                            {
                                this.props.recommendedRepos.map((repo) => {
                                    //@TODO This may change depending on how we implement getting the suggested Repos
                                    //@TODO Use the correct function to add a repo
                                    return (
                                        <div key={repo.id} className="layout horizontal center selected-repos-container">
                                            <span className="flex">{repo.name}</span>
                                            <div className="spacer-8"></div>
                                            <MdAdd onClick={() => this.linkToProject(repo)} className="icon-1 cursor-pointer" />
                                        </div>
                                    )
                                })
                            }
                        </div> */}
                            

                    </div>

                </>
            )
        }

        const ManageUsersFunc = () => {
            return (
                <> Manage users </>
            )
        }

        return (

            <div className="layout vertical full-height">

                <Dialog
                    open={this.state.openDialog}
                    onClose={() => this.handleClose("openDialog", false)}
                    classes={{ paper: 'dialog-shell' }}
                >
                    <AddProjectDialogContent manageRepos index={1} org={this.state.currentProject && this.state.currentProject.org} savedRepos={this.state.currentProject && this.state.currentProject.linkedRepos} handleDialogClose={() => this.handleClose("openDialog", false)} />
                </Dialog>


                <HeaderComponent
                    icon={
                        <Link to="/">
                            <IconButton>
                                <MdClear className="icon" />
                            </IconButton>
                        </Link>
                    }
                    title={<span className="truncate"> Project Settings </span>}
                />

                <div className="flex" style={{ backgroundColor: "var(--app-workspace-background-color)" }}>

                    <div className="layout horizontal full-height settings-container">

                        <div ref={this.settingsItemContainerRef} className="layout vertical full-height" style={{ width: "180px"}}>

                            {
                                this.state.items.map( (item) => {
                                    return (

                                        <div key={item.key} active={(this.state.selectedKey === item.key ? "true" : "false")} className="layout horizontal center settings-item-container" onClick={(e) => this.itemClick(e, item)}>
                                            <span className="layout horizontal center flex"> {item.title} </span>
                                            <div className="hover-div"></div>
                                            
                                        </div>
                                    )
                                })
                            }



                        </div>

                        <div className="layout vertical full-height flex" style={{ padding: "8px 40px" }}>
                            {
                                this.state.selectedKey === 'projectDetails'
                                ?
                                <ProjectDetailsFunc />
                                : this.state.selectedKey === 'syncLabels'
                                ? <SyncLabelsFunc />
                                : this.state.selectedKey === 'manageRepos'
                                    ? <ManageReposFunc />
                                : this.state.selectedKey === 'manageUsers'
                                    ? <ManageUsersFunc />
                                : ""
                            }

                        </div>

                    </div>

                    {/* <div className="layout vertical add-cards-container full-height">
                    </div> */}

                </div>
            </div>


        );

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettings);
