import Moment from 'moment';

import { SearchProcessor, GetSearchParameters, GetSearchStringWithoutParameters } from './AdvancedSearch';

/**
 * 
 * @param {Array} dataset 
 * @param {Object} filter 
 */

export function FilterProcessor(cards, filter, search) {

    if (!cards || !cards.length) return [];

    let searchParameters = GetSearchParameters(search.value);
    SearchProcessor(searchParameters, filter);

    // Filter by the order of user filter selection
    // Same type of filter is an Exclusive OR
    // Different type of filter is an Exclusive AND

    if(filter && filter.filter_order) {
        let order = filter.filter_order;
        // type: {},
        // assignees: {},
        // labels: {},
        // milestones: {},
        // repositories: {},
    
        for(var i = 0; i < order.length; i++) {
            if(order[i] === "labels") {
                cards = filterByLabels(cards, filter);
            } 
            else if (order[i] === "assignees") {
                cards = filterByAssignees(cards, filter);
            }
            else if (order[i] === "repositories") {
                cards = filterByRepository(cards, filter);
            }
            else if (order[i] === "type") {
                cards = filterByCardType(cards, filter);
            }
            else if (order[i] === "milestones") {
                cards = filterByMilestones(cards, filter);
            }
            else if (order[i] === "milestoneDate") {
                cards = filterByMilestoneDate(cards, filter);
            }
        }
    }

    let searchStr = GetSearchStringWithoutParameters(searchParameters);
    if(searchStr) {
        return searchCards(cards, searchStr);
    }

    return cards || []


}


function searchCards(cards, value) {
    for (var i = cards.length; i--;) {
        let _card = cards[i];
        if (_card.id) {
            let card = _card;
            let found = false;
            let isClosed = card && card.state === "CLOSED" ? true : false;
            let milestone = card && card.milestone && card.milestone.title
            let title = card ? card.title : "";
            let number = card ? card.number : undefined;
            if (!isClosed) {
                if ((title && title.toLowerCase().indexOf(value) >= 0) || (value === (number + "")) || (/* Milestone */ milestone && milestone.toLowerCase().indexOf(value) >= 0  ) ) {
                    found = true;
                }
            }
            
            if (!found) {
                cards.splice(i, 1);
            }
        }
        else {
            let card = _card.node;
            let found = false;
            let isClosed = card && card.content && card.content.state === "CLOSED" ? true : false;
            let number = card && card.content && card.content.number ;
            let title = card && card.content ? card.content.title : "";
            let milestone = card && card.content && card.content.milestone && card.content.milestone.title

            if (!isClosed) {
                if ((title && title.toLowerCase().indexOf(value) >= 0) || (value === (number + "")) || (/* Milestone */ milestone && milestone.toLowerCase().indexOf(value) >= 0) ) {
                    found = true;
                }
            }

            if (!found) {
                cards.splice(i, 1);
            }
        }
    }

    return cards

}

function filterByLabels(cards, filter) {
    for (var i = cards.length; i--;) {
        let _card = cards[i];
        if (_card.id) {

            let card = _card;
            let found = false;
            let isClosed = card  && card.state === "CLOSED" ? true : false;
            let labels = card  ? card.labels : [];

            if (!isClosed) {

                if (!found && labels && filter.labels) {
                    for (var j = 0; j < labels.length; j++) {
                        var node = labels[j]
                        if (filter.labels[node.name]) {
                            found = true;
                            break;
                        }
                    }
                }

            }

            if (!found) {
                cards.splice(i, 1);
            }

            
        }
        else {
            let card = _card.node;
            let found = false;
            let isClosed = card && card.content && card.content.state === "CLOSED" ? true : false;
            let labels = card && card.content ? card.content.labels : { nodes: [] };

            if (!isClosed) {
                
                if (!found && labels && labels.nodes && filter.labels) {
                    for (var j = 0; j < labels.nodes.length; j++) {
                        var node = labels.nodes[j]
                        if (filter.labels[node.name]) {
                            found = true;
                            break;
                        }
                    }
                }

            }

            if (!found) {
                cards.splice(i, 1);
            }
        }
    }

    return cards

}

function filterByAssignees(cards, filter) {
    for (var i = cards.length; i--;) {
        let _card = cards[i];

        if (_card.id) {

            let card = _card;
            let found = false;
            let isClosed = card && card.state === "CLOSED" ? true : false;
            let assignees = card && card.assignees ? card.assignees : [];

            if (!isClosed) {

                if (!found && assignees.length === 0 && filter.assignees["Unassigned"]) {
                    found = true;
                }

                if (!found && assignees && filter.assignees) {
                    for (var j = 0; j < assignees.length; j++) {
                        var node = assignees[j]
                        if (filter.assignees[node.login]) {
                            found = true;
                            break;
                        }
                    }

                }

            }

            if (!found) {
                cards.splice(i, 1);
            }


        }
        else {
            let card = _card.node;
            let found = false;
            let isClosed = card && card.content && card.content.state === "CLOSED" ? true : false;
            let assignees = card && card.content ? card.content.assignees : { nodes: [] };

            if (!isClosed) {

                if (!found && assignees && assignees[0].nodes.length === 0 && filter.assignees["Unassigned"]) {
                    found = true;
                }

                if (!found && assignees && assignees.nodes && filter.assignees) {
                    for (var j = 0; j < assignees.nodes.length; j++) {
                        var node = assignees.nodes[j]
                        if(filter.assignees[node.login]) {
                            found = true;
                            break;
                        }
                    }

                }

            }

            if (!found) {
                cards.splice(i, 1);
            }
        }
    }

    return cards
}

function filterByCardType(cards, filter) {
    for (var i = cards.length; i--;) {
        let _card = cards[i];
        if (_card.id) {

            let card = _card;
            let found = false;
            let isClosed = card && card.state === "CLOSED" ? true : false;
            let isPRCard = card && card.pull_request ? true : false

            if (!isClosed) {
                if (!found && filter.type) {
                    if (filter.type["PullRequest"] && isPRCard) {
                        found = true
                    }
                    if (filter.type["Issue"] && !isPRCard) {
                        found = true
                    }
                }
            }

            if (!found) {
                cards.splice(i, 1);
            }


        }        
        else {
            let card = _card.node;
            let found = false;
            let isClosed = card && card.content && card.content.state === "CLOSED" ? true : false;
            let typename = card && card.content ? card.content.__typename : '';

            if (!isClosed) {

                if (!found && filter.type && typename) {
                    if (filter.type[typename]) {
                        found = true;
                    }
                }

            }

            if (!found) {
                cards.splice(i, 1);
            }
        }
    }

    return cards
}

function filterByRepository(cards, filter) {
    for (var i = cards.length; i--;) {
        let _card = cards[i];
        if (_card.id) {

            let card = _card;
            let found = false;
            let isClosed = card && card.state === "CLOSED" ? true : false;
            let repositoryName = card && card.repository_url ? card.repository_url.substring(card.repository_url.lastIndexOf("/") + 1, card.repository_url.length) : 'no repo';

            if (!isClosed) {
                if (!found && filter.repositories && repositoryName) {
                    if (filter.repositories[repositoryName]) {
                        found = true;
                    }
                }
            }

            if (!found) {
                cards.splice(i, 1);
            };
        }
        else {
            let card = _card.node;
            let found = false;
            let isClosed = card && card.content && card.content.state === "CLOSED" ? true : false;
            let repositoryName = card && card.content ? card.content.repository.name : '';

            if (!isClosed) {

                if (!found && filter.repositories && repositoryName) {
                    if (filter.repositories[repositoryName]) {
                        found = true;
                    }
                }

            }

            if (!found) {
                cards.splice(i, 1);
            }
        }
    }

    return cards
}

function filterByMilestones(cards, filter) {
    for (var i = cards.length; i--;) {
        let _card = cards[i];
        if (_card.id) {
            
            let card = _card;
            let found = false;
            let isClosed = card && card.state === "CLOSED" ? true : false;
            let title = card && card.milestone && card.milestone.title;

            if (!isClosed) {
                if (!found && filter.milestones && title) {
                    if (filter.milestones[title]) {
                        found = true;
                    }
                }
            }

            if (!found) {
                cards.splice(i, 1);
            };
        }
        else {
            let card = _card.node;
            let found = false;
            let isClosed = card && card.content && card.content.state === "CLOSED" ? true : false;
            let title = card && card.content && card.content.milestone && card.content.milestone.title;

            if (!isClosed) {

                if (!found && filter.milestones && title) {
                    if (filter.milestones[title]) {
                        found = true;
                    }
                }

            }

            if (!found) {
                cards.splice(i, 1);
            }
        }
    }

    return cards

}

function filterByMilestoneDate(cards, filter) {
    for (let i = 0; i < cards.length; i++) {
        let _card = cards[i];
        if (_card.id) {
            let card = _card;
            let found = false;
            let isClosed = card && card.state === "CLOSED" ? true : false;
            let milestone = card && card.milestone;

            if (!isClosed) {
                if (!found && filter.milestoneDate && milestone) {
                    
                    let dueDate = Moment(milestone.due_on);
                    if (filter.milestoneDate.startDate && !filter.milestoneDate.endDate && dueDate.diff(Moment(filter.milestoneDate.startDate)) > 0) {
                        found = true;
                    } else if (!filter.milestoneDate.startDate && filter.milestoneDate.endDate && dueDate.diff(Moment(filter.milestoneDate.endDate)) < 0) {
                        found = true;
                    } else if (filter.milestoneDate.startDate && filter.milestoneDate.endDate && dueDate.diff(Moment(filter.milestoneDate.startDate)) > 0 && dueDate.diff(Moment(filter.milestoneDate.endDate)) < 0) {
                        found = true;
                    }
                }
            }

            if (!found) {
                cards.splice(i, 1);
                i -= 1;
            }
        } else {
            let card = _card.node;
            let found = false;
            let isClosed = card && card.content && card.content.state === "CLOSED" ? true : false;
            let milestone = card && card.content && card.content.milestone;

            if (!isClosed) {
                if (!found && filter.milestoneDate && milestone) {
                    let dueDate = Moment(milestone.dueOn);
                    if (filter.milestoneDate.startDate && !filter.milestoneDate.endDate && dueDate.diff(Moment(filter.milestoneDate.startDate)) > 0) {
                        found = true;
                    } else if (!filter.milestoneDate.startDate && filter.milestoneDate.endDate && dueDate.diff(Moment(filter.milestoneDate.endDate)) < 0) {
                        found = true;
                    } else if (filter.milestoneDate.startDate && filter.milestoneDate.endDate && dueDate.diff(Moment(filter.milestoneDate.startDate)) > 0 && dueDate.diff(Moment(filter.milestoneDate.endDate)) < 0) {
                        found = true;
                    }
                }
            }

            if (!found) {
                cards.splice(i, 1);
                i -= 1;
            }
        }
    };
    return cards;
}