import React from 'react';
import { connect } from "react-redux";

import Popover from '@material-ui/core/Popover';
import { MdChevronRight, MdRefresh, MdClear } from 'react-icons/md';

import { ClearButton } from "../styled-components/Buttons/Buttons";
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';

import "../../styles/common.css";
import "../../styles/common.nav-bar-2.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";

import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';

const ExpansionPanel = withStyles({
    root: {
        border: 'none',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    },
    expanded: {
        margin: 'auto',
    },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0,0,0,.03)',
        // borderBottom: '1px solid rgba(0,0,0,.125)',
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
        padding: '0 16px',
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(props => <MuiExpansionPanelSummary {...props} />);

const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        padding: '0 16px',
    },
}))(MuiExpansionPanelDetails);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const FilterCheckbox = withStyles({
    root: {
        color: 'var(--primary-text-color)',
        '&$checked': {
            color: 'var(--primary-text-color)',
        },
    },
    checked: {},
})(Checkbox);

class Filter extends React.Component {
    state = {
        filterAnchor: null,
        checked: {
            // type: {},
            // assignees: {},
            // labels: {},
            // milestones: {},
            // repositories: {},
            // milestoneDate: {}
        },
    };

    handleExpand = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleCheckChange = (name, category) => event => {
        var checked = this.state.checked;
        if(!checked[category]) {
            checked[category] = {}
        }
        
        if(!checked.filter_order) {
            checked.filter_order = [];
        }

        checked[category][name] = event.target.checked ? event.target.checked : undefined;
        
        // delete if false
        !checked[category][name] && (delete checked[category][name]);
        
        if (Object.keys(checked[category]).length == 0) {
            delete checked[category]

            // delete category from filter_order
            checked.filter_order.splice(checked.filter_order.indexOf(category), 1);

            if (checked.filter_order.length === 0) {
                delete checked.filter_order;
            }

        } else {
            // check if the order already exists

            if(checked.filter_order.indexOf(category) < 0) {
                checked.filter_order.push(category);
            }
        }

        this.setState({ checked: checked });
        setTimeout(this.props.updateSelectedFilter(this.state.checked), 1000)
        
    };

    // handleValueChange = (name, category) => event => {
    //     var checked = this.state.checked;
    //     if(!checked[category]) {
    //         checked[category] = {}
    //     }
        
    //     if(!checked.filter_order) {
    //         checked.filter_order = [];
    //     }

    //     checked[category][name] = event.target.value ? event.target.value : undefined;
        
    //     // delete if false
    //     !checked[category][name] && (delete checked[category][name]);
        
    //     if (Object.keys(checked[category]).length == 0) {
    //         delete checked[category]

    //         // delete category from filter_order
    //         checked.filter_order.splice(checked.filter_order.indexOf(category), 1);

    //         if (checked.filter_order.length === 0) {
    //             delete checked.filter_order;
    //         }

    //     } else {
    //         // check if the order already exists

    //         if(checked.filter_order.indexOf(category) < 0) {
    //             checked.filter_order.push(category);
    //         }
    //     }

    //     this.setState({ checked: checked });
    //     setTimeout(this.props.updateSelectedFilter(this.state.checked), 1000)
        
    // };

    clearFilter = () => {

        this.state.checked = {
            // type: {},
            // assignees: {},
            // labels: {},
            // milestones: {},
            // repositories: {},
        }
        this.setState({ checked: this.state.checked });
        this.props.updateSelectedFilter({});
    }

    render() {
        const filterOpen = Boolean(this.props.anchor);
        const { expanded } = this.state;

        return (
            <Popover
                className="filter-popover"
                anchorEl={this.props.anchor}
                open={filterOpen}
                onClose={this.props.onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                classes={{ paper: 'filter-popover' }} 
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className="layout vertical filter-container full-height">
                    <div className="layout horizontal center filter-header">
                        <div>Filter</div>
                        <div className="flex"></div>
                        <IconButton title="Reset filters" onClick={this.clearFilter}>
                            <MdRefresh className="icon" />
                        </IconButton>
                        <IconButton onClick={this.props.onClose}>
                            <MdClear  className="icon" />
                        </IconButton>
                    </div>
                
                    <div className="flex container-overflow-y filter-body">
                        <ExpansionPanel expanded={expanded === 'type'} onChange={this.handleExpand('type')} className="filter-menu-expansion-panel">
                            <ExpansionPanelSummary className="layout horizontal center filter-menu-title-container">
                                {
                                    expanded === 'type' ? <MdChevronRight className="rotate-90 chevron" /> : <MdChevronRight className="chevron"/>
                                }
                                <div className="filter-menu-title">
                                    Type
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="fitler-menu-detail">
                                <FormGroup>
                                    {
                                        this.props.filter &&
                                        this.props.filter.type &&
                                        this.props.filter.type.map((type) => {
                                            return (
                                                <FormControlLabel
                                                    key={type}
                                                    control={
                                                        <FilterCheckbox disableRipple={true}
                                                            checked={this.state.checked.type && this.state.checked.type[type] ? true : false}
                                                            onChange={this.handleCheckChange(type, 'type')}
                                                        // value="ncraleyChecked"
                                                        />
                                                    }
                                                    label={type}
                                                />
                                            )
                                        })

                                    }
                                </FormGroup>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'assigned'} onChange={this.handleExpand('assigned')} className="filter-menu-expansion-panel">
                            <ExpansionPanelSummary className="layout horizontal center filter-menu-title-container">
                                {
                                    expanded === 'assigned' ? <MdChevronRight className="rotate-90 chevron" /> : <MdChevronRight className="chevron" />
                                }
                                <div className="filter-menu-title">
                                    Assigned
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="fitler-menu-detail">
                                <FormGroup>
                                    {
                                        this.props.filter && 
                                        this.props.filter.assignees && 
                                        this.props.filter.assignees.map((assignee) => {
                                            return (
                                                <FormControlLabel
                                                    key={assignee}
                                                    control={
                                                        <FilterCheckbox disableRipple={true}
                                                            checked={this.state.checked.assignees && this.state.checked.assignees[assignee] ? true : false}
                                                            onChange={this.handleCheckChange(assignee , 'assignees')}
                                                            // value="ncraleyChecked"
                                                        />
                                                    }
                                                    label={assignee}
                                                />
                                            )
                                        })

                                    }
                                </FormGroup>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'labels'} onChange={this.handleExpand('labels')} className="filter-menu-expansion-panel">
                            <ExpansionPanelSummary className="layout horizontal center filter-menu-title-container">
                                {
                                    expanded === 'labels' ? <MdChevronRight className="rotate-90 chevron" /> : <MdChevronRight className="chevron" />
                                }

                                <div className="filter-menu-title">
                                    Labels
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="fitler-menu-detail">
                                <FormGroup>
                                    {
                                        this.props.filter &&
                                        this.props.filter.labels &&
                                        this.props.filter.labels.map((label) => {
                                            return (
                                                <FormControlLabel
                                                    key={label}
                                                    control={
                                                        <FilterCheckbox disableRipple={true}
                                                            checked={this.state.checked.labels && this.state.checked.labels[label] ? true : false}
                                                            onChange={this.handleCheckChange(label, 'labels')}
                                                        />
                                                    }
                                                    label={label}
                                                />
                                            )
                                        })

                                    }
                                </FormGroup>

                            </ExpansionPanelDetails>
                        </ExpansionPanel>

                        <ExpansionPanel expanded={expanded === 'milestones'} onChange={this.handleExpand('milestones')} className="filter-menu-expansion-panel">
                            <ExpansionPanelSummary className="layout horizontal center filter-menu-title-container">
                                {
                                    expanded === 'milestones' ? <MdChevronRight className="rotate-90 chevron" /> : <MdChevronRight className="chevron" />
                                }

                                <div className="filter-menu-title">
                                    Milestones by Name
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="fitler-menu-detail">
                                <FormGroup>

                                    {
                                        this.props.filter &&
                                        this.props.filter.milestones &&
                                        this.props.filter.milestones.map((milestone) => {
                                            return (
                                                <FormControlLabel
                                                    key={milestone}
                                                    control={
                                                        <FilterCheckbox disableRipple={true}
                                                            checked={this.state.checked.milestones && this.state.checked.milestones[milestone] ? true : false}
                                                            onChange={this.handleCheckChange(milestone, 'milestones')}
                                                        />
                                                    }
                                                    label={milestone}
                                                />
                                            )
                                        })

                                    }
                                </FormGroup>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>

                        {/* <ExpansionPanel expanded={expanded === 'milestoneDate'} onChange={this.handleExpand('milestoneDate')} className="filter-menu-expansion-panel">
                            <ExpansionPanelSummary className="layout horizontal center filter-menu-title-container">
                                {
                                    expanded === 'milestoneDate' ? <MdChevronRight className="rotate-90 chevron" /> : <MdChevronRight className="chevron" />
                                }

                                <div className="filter-menu-title">
                                    Milestones By Date
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="fitler-menu-detail">
                                <FormGroup>
                                    <InputLabel
                                        label="Start Date"
                                        children={
                                            <Input
                                                type="date"
                                                placeholder="Enter a date"
                                                value={this.state.milestoneDateStart}
                                                onChange={this.handleValueChange("startDate", "milestoneDate")}
                                            />
                                        }
                                    />
                                    <InputLabel
                                        label="End Date"
                                        children={
                                            <Input
                                                type="date"
                                                placeholder="Enter a date"
                                                value={this.state.milestoneDateEnd}
                                                onChange={this.handleValueChange("endDate", "milestoneDate")}
                                            />
                                        }
                                    />
                                </FormGroup>
                                
                            </ExpansionPanelDetails>
                        </ExpansionPanel> */}

                        <ExpansionPanel expanded={expanded === 'repositories'} onChange={this.handleExpand('repositories')} className="filter-menu-expansion-panel">
                            <ExpansionPanelSummary className="layout horizontal center filter-menu-title-container">
                                <div>
                                    {
                                        expanded === 'repositories' ? <MdChevronRight className="rotate-90 chevron" /> : <MdChevronRight className="chevron" />
                                    }
                                </div>
                                <div className="filter-menu-title">
                                    Repositories
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="fitler-menu-detail">
                                <FormGroup>

                                    {
                                        this.props.filter &&
                                        this.props.filter.repositories &&
                                        this.props.filter.repositories.map((repo) => {
                                            return (
                                                <FormControlLabel
                                                    key={repo}
                                                    control={
                                                        <FilterCheckbox disableRipple={true}
                                                            checked={this.state.checked.repositories && this.state.checked.repositories[repo] ? true : false}
                                                            onChange={this.handleCheckChange(repo, 'repositories')}
                                                        />
                                                    }
                                                    label={repo}
                                                />
                                            )
                                        })

                                    }
                                </FormGroup>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                </div>

            </Popover>
        );
    }
}

export default Filter;