import React from 'react';
import { connect } from "react-redux";

import { MdClear } from 'react-icons/md';
import IconButton from '@material-ui/core/IconButton';

import { HeaderComponent } from "./HeaderComponent";
import TextField from '@material-ui/core/TextField';
import { NormalTextField } from "../styled-components/TextFields/TextFields";
import { TransparentButton } from "../styled-components/Buttons/Buttons";

import { addProjectColumn, updateProjectColumn } from "../../redux/actions/index";

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addProjectColumn: name => dispatch(addProjectColumn({name: name})),
        updateProjectColumn: (name, column) => dispatch(updateProjectColumn(name, column)),
    }
}

class AddColumnDialogContent extends React.Component {
    state = {
        open: false,
        name: (this.props.column && this.props.column.node && this.props.column.node.name) || "",

    }

    addProjectColumn() {
        this.props.addProjectColumn(this.state.name);
        this.props.handleDialogClose();
    }

    updateProjectColumn () {
        this.props.updateProjectColumn(this.state.name, this.props.column);
        this.props.handleDialogClose();
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    constructor(props, context) {
        super(props, context);
        this.addProjectColumn = this.addProjectColumn.bind(this)
        this.updateProjectColumn = this.updateProjectColumn.bind(this)
    }

    render() {
        return (
            <div className="layout vertical dialog-container">

                <HeaderComponent
                    suffix={
                        <IconButton
                            onClick={this.props.handleDialogClose}
                        >
                            <MdClear className="icon" />
                        </IconButton>
                    }
                    title={<span className="truncate"> {this.props.edit ? "Edit column" : "Add column"} </span>}
                />

                <div className="flex container-overflow-y">
                    
                    <div className="layout horizontal form-margin">
                        <NormalTextField id="newColumnTextField" label="Column name" custommargin="true" placeholder="Enter column name" className="flex" value={this.state.name} onChange={this.handleChange('name')} margin="normal" InputLabelProps={{ shrink: true, }} />
                    </div>

                </div>
                
                <div className="layout horizontal center container-56">
                    <div className="flex"></div>
                    {
                        this.props.edit ? 
                            <TransparentButton disabled={this.state.name ? false : true}  onClick={this.updateProjectColumn}>
                                Update
                            </TransparentButton>
                        :
                            <TransparentButton disabled={this.state.name ? false : true}  onClick={this.addProjectColumn}>
                                Create
                            </TransparentButton>
                    }
                </div>

            </div>

        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(AddColumnDialogContent)


