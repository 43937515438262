import gql from 'graphql-tag'



const FRAGEMENT_CARD = `

    fragment card on ProjectCardEdge {

        cursor

        node {
            id
            databaseId
            note
            url
            content {
            __typename
            ...on Issue {
                    id
                    databaseId
                    title
                    number
                    state
                    url
                    repository {
                        name
                        id
                        databaseId
                        owner {
                            login
                            id
                        }
                    }
                    assignees(first: 4) {
                        nodes {
                            id
                            avatarUrl
                            name
                            login
                        }
                    }
              		milestone {
                      dueOn
                      id
                      state
                      title
                      url
                      
                    }                    
                    labels(first: 5) {
                        nodes {
                            id
                            name
                            color
                        }
                    }
                }
            ...on PullRequest {
                    id
                    databaseId
                    title
                    number
                    url
                    repository {
                        name
                        owner {
                            login
                            id
                        }
                    }
                    comments {
                        totalCount
                    }
                    reviewRequests(last: 1) {
                        nodes {
                            id
                            requestedReviewer {
                                __typename
                                ...on User {
                                    avatarUrl
                                    name
                                    login
                                    
                                }
                            }
                        }
                    }
                    reviews(last: 1) {
                        nodes {
                            author {
                                avatarUrl
                                login
                            }
                            body
                        }
                    }                    

                    commits(last: 1){
                        nodes{
                            commit{
                            commitUrl
                            oid
                            status {
                                state
                                
                                contexts {
                                state
                                targetUrl
                                description
                                context
                                }
                            }
                            }
                        }
                    }
                    updatedAt
                    url
                    milestone {
                        dueOn
                    }
                }
            }
        }

    }

`;

const FRAGMENT_PROJECT = `

    fragment project on Project {
        columns(first: 1, after: $cursor) {
            totalCount
            edges {
                cursor
                node {
                    id
                    databaseId
                    name
                    cards(first: 100, after: $cardCursor) {
                        totalCount
                        edges {
                            ...card
                        }
                    }
                }
            }
        }      
    }

    ${FRAGEMENT_CARD}

`;


export const GET_PROJECT_DETAIL = `


    query GetSingleProject($number: Int!, $cursor: String, $cardCursor: String, $org: String!) {
        # krumboard - ui
        organization(login: $org) {
            name
            project(number: $number) {
                id
                databaseId
                ...project
            }	

        }
        rateLimit {
            limit
            cost
            remaining
            resetAt
        }
    }
    ${FRAGMENT_PROJECT}

`
;