import React, { Component } from 'react';
import { connect } from "react-redux";
import { Draggable, Droppable } from 'react-beautiful-dnd';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ExpandIcon from "../CustomIcons/ExpandIcon";
import { MdClear, MdEdit, MdDelete } from 'react-icons/md';
import { saveColumnState } from "../../redux/actions/index";
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Card from './Card.js';
import { FilterProcessor } from "../utils/FilterProcessor";

const mapStateToProps = state => {
    return {
        columnStateCollections: state.columnStateCollections,
        selectedFilter: state.selectedFilter,
        search: state.search,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveColumnState: (key, state) => dispatch(saveColumnState(key, state)),
    }
}
class Column extends Component {

    state = {
        showColumnSettingsBool: this.props.columnStateCollections && this.props.columnKey && this.props.columnStateCollections[this.props.columnKey] && this.props.columnStateCollections[this.props.columnKey].showColumnSettingsBool,
        
        smallScreen: false,
        selectedFilter: this.props.selectedFilter || {},
        columnCollapsed: this.props.columnStateCollections && this.props.columnKey && this.props.columnStateCollections[this.props.columnKey] && this.props.columnStateCollections[this.props.columnKey].columnCollapsed,
    }

    constructor(props) {
        super(props);
    }

    calculateFontColor = (color) => {
        var r = color.substring(0, 2);
        var g = color.substring(2, 4);
        var b = color.substring(4)
        var calcVal = parseInt(r, 16)*0.299 + parseInt(g, 16)*0.587 + parseInt(b, 16)*0.114;
        if (calcVal > 127) {
            return '#000';
        }
        else {
            return '#fff';
        }
    }

    onDrop (event) {
        console.log("on drop", event)
    }

    openCardsPanel = () => {
        window.dispatchEvent(new CustomEvent('open-add-cards-panel'));
    }

    showColumnSettings = () => {
        this.state.showColumnSettingsBool = true;
        this.setState({
            showColumnSettingsBool: this.state.showColumnSettingsBool
        });
        this.props.saveColumnState && this.props.saveColumnState(this.props.columnKey, this.state)
    }

    hideColumnSettings = () => {
        this.state.showColumnSettingsBool = false;
        this.setState({
            showColumnSettingsBool: this.state.showColumnSettingsBool
        });
        this.props.saveColumnState && this.props.saveColumnState(this.props.columnKey, this.state)
    }

    showColumnNameDialog = (column) => {
        window.dispatchEvent(new CustomEvent('open-column-dialog', { detail: column }));
    }

    collapseColumn = () => {
        this.state.columnCollapsed = true;
        this.setState({ columnCollapsed: this.state.columnCollapsed });
        this.props.saveColumnState && this.props.saveColumnState(this.props.columnKey, this.state)
    }

    uncollapseColumn = () => {
        this.state.columnCollapsed = false;
        this.state.showColumnSettingsBool = false;
        this.setState({ columnCollapsed: this.state.columnCollapsed });
        this.props.saveColumnState && this.props.saveColumnState(this.props.columnKey, this.state)
    }


    checkWidth = () => {
        const match = window.matchMedia(`(max-width: 768px)`).matches;
        if (match == this.state.smallScreen) return;
        if (match) {
            this.setState({ smallScreen: true });
        } else {
            this.setState({ smallScreen: false });
        }
    }

    componentDidMount() {
        this.checkWidth();
        window.addEventListener("resize", this.checkWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkWidth);
    }

    getOpenIssuesCount(cards, showSuffix) {
        
        var count = 0;
        if(!cards) {
            return
        }

        cards = this.filterColumn(cards)
        
        for (let i = 0; i < cards.length; i++) {
            var card = cards[i];
            if (card && card.node && card.node.content && card.node.content.state === "CLOSED") {}
            else {
                count++;
            }
            
        }
        
        if(showSuffix) return count + " " + ( count == 1 ? "card" : "cards");
        else return count;
    }

    filterColumn( cards) {
        var filter = this.props.selectedFilter;
        var search = this.props.search;
        return FilterProcessor(JSON.parse(JSON.stringify(cards)), filter, search);
    }

    checkFilter = (filter) => {
        if (!filter) {
            return false
        }

        if (Object.keys(filter) == 0) {
            return false;
        }

        return true;

    }

  render() {
    var column = this.props.column;
    return (

        <>
            {
                this.state.columnCollapsed ?
                    <div className="layout vertical workspace-column column-padding-override">
                        <IconButton title="Expand" onClick={() => this.uncollapseColumn()}>
                        <ExpandIcon />
                    </IconButton>
                        <p className="flex  vertical-center  vertical-text">{column.node.name}</p>
                        <span className="font-size-12 text-center">{this.getOpenIssuesCount(column.node.cards.edges, false)}</span>
                </div>
                :

                !this.state.showColumnSettingsBool ?
                
                    <Droppable key={column.node.id} onDragEnd={this.onDrop.bind(this)} droppableId={column.node.id} type="CARD">
                        {(provided, snapshot) => (
                            <div key={column.node.id} ref={provided.innerRef}  {...provided.droppableProps} className="layout vertical workspace-column">
                                <div className="layout horizontal center column-title">
                                    <span>{column.node.name}</span>
                                    <div className="flex"></div>
                                    {
                                        !this.state.showColumnSettingsBool ? 
                                        <MoreHorizIcon className="cursor-pointer" onClick={  () => this.showColumnSettings()} />
                                        :
                                        <MdClear className="cursor-pointer" onClick={() => this.hideColumnSettings()} />

                                    }
                                </div>

                                <div className="flex container-overflow-y card-container">
                                    {
                                        column.node.cards.edges && column.node.cards.edges.length ?
                                            column.node.cards.edges && this.filterColumn(column.node.cards.edges).map((card, index) => {
                                                if (card.node) {
                                                    return (
                                                        <Card
                                                            className="column-card"
                                                            card={card.node}
                                                            smallScreen={this.state.smallScreen}
                                                            identifier={card.node.id}
                                                            key={"card_" + card.node.id}
                                                            index={index}
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <Card
                                                            restApi={true}
                                                            className="column-card"
                                                            smallScreen={this.state.smallScreen}
                                                            card={card}
                                                            identifier={card.id}
                                                            key={"card_" + card.id}
                                                            index={index}
                                                        />
                                                    )
                                                }
                                            })

                                            : ""

                                    }
                                    {provided.placeholder}
                                </div>

                                {
                                    !this.state.showColumnSettingsBool ?
                                        <div className="layout horizontal center container-32">
                                            <span className="font-size-12">
                                                {
                                                    this.getOpenIssuesCount(column.node.cards.edges, true)
                                                }
                                            </span>
                                        </div>
                                    : ""
                                }

                            </div>
                        )}
                    </Droppable>
                :

                <div className="layout vertical workspace-column">
                    <div className="layout horizontal center column-title">
                        <span>{column.node.name}</span>
                        <div className="flex"></div>
                        {/* <MdAdd className="cursor-pointer" onClick={() => this.openCardsPanel()} style={{ marginRight: "8px", width: '24px', height: '24px'}} /> */}
                        <MdClear className="cursor-pointer" onClick={() => this.hideColumnSettings()} />
                    </div>
                    <div className="card-container">
                        <List component="nav">
                            {/* <ListItem
                                    button
                                    onClick={event => this.showColumnNameDialog(this.props.column)}
                                >
    
                                    </ListItem> */}
                            <div onClick={event => this.showColumnNameDialog(this.props.column)} className="layout horizontal center container-32 cursor-pointer">
                                {/* <MdEdit />
                                    <div className="spacer-8"></div> */}
                                <span>Change column name</span>
                            </div>
                            <div className="height-10"></div>
                            <div hidden={this.state.smallScreen} onClick={event => this.collapseColumn()} className="layout horizontal center container-32 cursor-pointer">
                                {/* <MdEdit />
                                    <div className="spacer-8"></div> */}
                                <span>Collapse column</span>
                            </div>
    
                            {/* <div onClick={event => this.showColumnNameDialog(this.props.column)} className="layout horizontal center container-32 cursor-pointer">
                                    <MdDelete />
                                    <div className="spacer-8"></div>
                                    <span>Delete column</span>
                                </div> */}
    
                        </List>
                    </div>
                </div>    


            }
        </>

      )
    }

};


export default connect(mapStateToProps, mapDispatchToProps)(Column);
