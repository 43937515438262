import React, { Component } from 'react';
import { connect } from "react-redux";
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from "react-router-dom";

import Card from './Card.js';
import { FilterProcessor } from "../utils/FilterProcessor";

const mapStateToProps = state => {
    return {
        hopperIssues: state.hopperIssues,
        currentProjectInfo: state.currentProjectInfo,
        selectedFilter: state.selectedFilter,
        search: state.search,
        hopperLoadingState: state.hopperLoadingState || false,
        recommendedReposPresent: state.recommendedRepos && state.recommendedRepos.length > 0,
        recommendedReposNum: state.recommendedRepos && state.recommendedRepos.length
    };
};

class BacklogColumn extends Component {

    state = {
        smallScreen: false,
    }

    constructor(props) {
        super(props);
    }

    onDrop(event) {
        console.log("on drop", event)
    }

    checkWidth = () => {
        const match = window.matchMedia(`(max-width: 768px)`).matches;
        if (match == this.state.smallScreen) return;
        if (match) {
            this.setState({ smallScreen: true });
        } else {
            this.setState({ smallScreen: false });
        }
    }

    componentDidMount() {
        this.checkWidth();
        window.addEventListener("resize", this.checkWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkWidth);
    }

    filterColumn(cards) {
        var filter = this.props.selectedFilter
        var search = this.props.search;

        return FilterProcessor(JSON.parse(JSON.stringify(cards)), filter, search);
    }

    checkFilter = (filter) => {
        if (!filter) {
            return false
        }

        if (Object.keys(filter) == 0) {
            return false;
        }

        return true;

    }

    getLength = () => {
        var edges = this.props.currentProjectInfo[0] &&
            this.props.currentProjectInfo[0].node && this.props.currentProjectInfo[0].node.cards && this.filterColumn(this.props.currentProjectInfo[0].node.cards.edges);

        if(edges) {
            return edges.length === 1 ? "1 card" : edges.length + " cards";
        }

        return "0 cards";
    }

    render() {
        var column = this.props.column;
        return (

            <Draggable isDragDisabled={true} key={"column_" + column.node.id} draggableId={column.node.id} index={this.props.index}>
                {(provided, snapshot) => (
                    <div className="full-height" key={column.node.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="layout vertical workspace-column">

                        <div className="layout horizontal center column-title">
                            <span> Hopper </span>
                        </div>

                        

                        
                        
                        {/* isDropDisabled={this.checkFilter(this.props.selectedFilter)} */}
                        <Droppable  key={column.node.id} onDragEnd={this.onDrop.bind(this)} droppableId={column.node.id} type="CARD">
                            {(provided, snapshot) => (
                                <div key={column.node.id} ref={provided.innerRef}  {...provided.droppableProps} className="flex container-overflow-y card-container">
                                    {/*  this.props.currentProjectInfo[0] && 
                                        this.props.currentProjectInfo[0].node && this.props.currentProjectInfo[0].node.cards && this.props.currentProjectInfo[0].node.cards.edges && */}
                                    {
                                        this.props.currentProjectInfo[0] &&
                                        this.props.currentProjectInfo[0].node && this.props.currentProjectInfo[0].node.cards && this.props.currentProjectInfo[0].node.cards.edges && 
                                        this.filterColumn(this.props.currentProjectInfo[0].node.cards.edges).length ? this.props.currentProjectInfo[0] &&
                                        this.props.currentProjectInfo[0].node && this.props.currentProjectInfo[0].node.cards && this.props.currentProjectInfo[0].node.cards.edges && 
                                        this.filterColumn(this.props.currentProjectInfo[0].node.cards.edges).map((card, index) => {
                                            if (card.node) {
                                                return (
                                                    <Card
                                                        className="column-card"
                                                        card={card.node}
                                                        smallScreen={this.state.smallScreen}
                                                        identifier={card.node.id}
                                                        key={"card_" + card.node.id}
                                                        index={index}
                                                    />
                                                )
                                            } else {
                                                return (
                                                    <Card
                                                        restApi={true}
                                                        className="column-card"
                                                        smallScreen={this.state.smallScreen}
                                                        card={card}
                                                        identifier={card.id}
                                                        key={"card_" + card.id}
                                                        index={index}
                                                    />
                                                )
                                            }

                                            // return (
                                            //     <Card
                                            //         restApi={true}
                                            //         className="column-card"
                                            //         card={card}
                                            //         identifier={card.id}
                                            //         key={"card_" + card.id}
                                            //         index={index}
                                            //     />
                                            // )

                                        }) : 

                                            this.props.hopperLoadingState ? (
                                                <div className="layout vertical full-height center app-workspace flex vertical-center">
                                                    {/* <NoCardsIcon style={{ width: "222px", height: "167px" }} /> */}
                                                    <div className="height-10"></div>
                                                    <span className="title-bold-24">Hopper is Loading...</span>
                                                    <div className="height-10"></div>
                                                    <span className="title-16 opactiy-54 text-center"> </span>
                                                </div>
                                            ) : (
                                                <div className="layout vertical full-height center app-workspace flex vertical-center">
                                                    {/* <NoCardsIcon style={{ width: "222px", height: "167px" }} /> */}
                                                    <div className="height-10"></div>
                                                    <span className="title-bold-24">Nothing to see here!</span>
                                                    <div className="height-10"></div>
                                                    
                                                    <span className="title-16 opactiy-54 text-center">Click on the settings button, and link a repo to see some cards show up here</span>
                                                </div>
                                        )
                                    }
                                </div>
                            )}
                        </Droppable>
                        <div className="layout horizontal center container-32">
                            <span className="font-size-12">{this.getLength()} </span>
                            <div className="flex"></div>
                            {
                                this.props.recommendedReposPresent ? <>
                                    <Link to="/settings" className="font-size-12"> {this.props.recommendedReposNum} Recommended {this.props.recommendedReposNum === 1 ? "Repo" : "Repos" } </Link>
                                </>:""
                            }
                        </div>
                    </div>
                )}
            </Draggable>

        )
    }
}

export default connect(mapStateToProps, null)(BacklogColumn);