const rp = require("request-promise");

export const getRepoByID = async (token, id) => {
    if(!id) {
        return;
    }
    return rp.get({
        url: process.env.REACT_APP_GITHUB_ENDPOINT + "/repositories/" + id + "?access_token=" + token,
        headers: {
            'Authorization': 'token ' + token,
            'User-Agent': "Krumboard",
            'Accept': 'application/vnd.github.nightshade-preview+json'
        },
        json: true
    })
}

export const getIssuesForRepo = async (token, owner, repo_name, page=1, per_page=100) => {
    return rp.get({
        url: process.env.REACT_APP_GITHUB_ENDPOINT + "/repos/" + owner + "/" + repo_name + "/issues" + "?per_page=" + per_page + "&page=" + page + "&access_token=" + token,
        headers: {
            'Authorization': 'token ' + token,
            'User-Agent': "Krumboard",
            'Accept': 'application/vnd.github.nightshade-preview+json'
        },
        json: true
    })
}

export const getPullRequestsForRepo = async (token, owner, repo_name, page = 1, per_page = 100) => {
    return rp.get({
        url: process.env.REACT_APP_GITHUB_ENDPOINT + "/repos/" + owner + "/" + repo_name + "/pulls" + "?per_page=" + per_page + "&page=" + page + "&access_token=" + token,
        headers: {
            'Authorization': 'token ' + token,
            'User-Agent': "Krumboard",
            'Accept': 'application/vnd.github.nightshade-preview+json'
        },
        json: true
    })
}



export const createProjectCard = async (token, column_id, content_id, content_type) => {
    //  + "&content_id=" + content_id + "&content_type=" + content_type
    return rp.post({
        url: process.env.REACT_APP_GITHUB_ENDPOINT + "/projects/columns/" + column_id + "/cards?access_token=" + token,
        body: {
            content_id: content_id,
            content_type: content_type
        },
        headers: {
            'Authorization': 'token ' + token,
            'User-Agent': "Krumboard",
            'Accept': 'application/vnd.github.inertia-preview+json'
        },
        json: true
    })
}

export const deleteProjectCard = async (token, card_id) => {
    //  + "&content_id=" + content_id + "&content_type=" + content_type
    return rp.delete({
        url: process.env.REACT_APP_GITHUB_ENDPOINT + "/projects/columns/cards/" + card_id + "?access_token=" + token,
        headers: {
            'Authorization': 'token ' + token,
            'User-Agent': "Krumboard",
            'Accept': 'application/vnd.github.inertia-preview+json'
        },
        json: true
    })
}

export const getIssueDetails = async (token, owner, repo_name, number) => {
    return rp.get({
        url: process.env.REACT_APP_GITHUB_ENDPOINT + "/repos/" + owner + "/" + repo_name + "/issues/" + number + "?access_token=" + token,
        headers: {
            'Authorization': 'token ' + token,
            'User-Agent': "Krumboard",
            'Accept': 'application/vnd.github.symmetra-preview+json'
        },
        json: true
    })
}

export const markdownToHTML = async(token, text) => {
    return rp.post({
        url: process.env.REACT_APP_GITHUB_ENDPOINT + "/markdown" + "?access_token=" + token,
        body: {
            text: text
        },
        headers: {
            'Authorization': 'token ' + token,
            'User-Agent': "Krumboard",
        },
        json: true
    })
    
}


export const getUserRepositories = async (token) => {
    return rp.get({
        url: process.env.REACT_APP_GITHUB_ENDPOINT + "/user/repos?per_page=1000&access_token=" + token,
        headers: {
            'Authorization': 'token ' + token,
            'User-Agent': "Krumboard",
            'Accept': 'application/vnd.github.nightshade-preview+json'
        },
        json: true
    })
}


export const commentAsUser = async (obj, body, token) => {

    try {
        return await rp.post({
            url: obj.comments_url + "?access_token=" + token,
            body: { body: body },
            headers: {
                "Authorization": "token " + token,
                'Accept': 'application/vnd.github.nightshade-preview+json',
                'User-Agent': "application/vnd.github.VERSION.raw+json",
            },
            json: true
        })
    } catch (e) {
        return { status: 500, errored: true, err: e }
    }
}

export const getInvitations = async (username, token) => {
    return rp.get({
        uri: process.env.REACT_APP_GITHUB_ENDPOINT + "/user/repository_invitations?access_token=" + token,
        headers: {
            'User-Agent': "application/vnd.github.VERSION.raw+json"
        },
        json: true
    })

}


export const acceptInvitation = async (id, token) => {
    var options = {
        method: 'PATCH',
        uri: process.env.REACT_APP_GITHUB_ENDPOINT + "/user/repository_invitations/" + id + "?access_token=" + token,
        headers: {
            'User-Agent': "application/vnd.github.symmetra-preview+json"
        },
        json: true // Automatically stringifies the body to JSON
    };
    rp(options)
    // return rp(op?tions)
}
