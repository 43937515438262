import React from 'react';
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SwipeableViews from 'react-swipeable-views';
import NoCoumnsIcon from "../CustomIcons/NoColumnsIcon";
import NoProjectSelectedIcon from "../CustomIcons/NoProjectSelectedIcon";
import  { SecondaryButton } from "../styled-components/Buttons/Buttons";
import uuid from "uuid";

import Column from './Column.js';
import BacklogColumn from './BacklogColumn.js';

import {  moveProjectCard } from "../../redux/actions/index";
import { FilterProcessor } from "../utils/FilterProcessor";

import "../../styles/common.workspace.css";
import "../../styles/common.animation.css";

const mapStateToProps = state => {
    return {
        currentProject: state.currentProject,
        currentProjectInfo: state.currentProjectInfo,
        selectedFilter: state.selectedFilter,
        search: state.search,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        moveProjectCard: (payload) => dispatch(moveProjectCard(payload)),
    }
}

class AppWorkspace extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: [],
            selectedFilter: this.props.selectedFilter || {},
            smallScreen: false
        };
        this.checkWidth = this.checkWidth.bind(this);
    }


    checkWidth = () => {
        const match = window.matchMedia(`(max-width: 768px)`).matches;
        if (match == this.state.smallScreen) return;
        if (match) {
            this.setState({ smallScreen: true });
        } else {
            this.setState({ smallScreen: false });
        }
    }

    componentDidMount() {
        this.checkWidth();
        window.addEventListener("resize", this.checkWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkWidth);
    }


    componentDidUpdate() {
    }

    openColumnDialog () {
        window.dispatchEvent(new CustomEvent('open-column-dialog'));
    }

    reorderColumns = (sourceIndex, destIndex) => {
        var list = FilterProcessor(this.props.currentProjectInfo, this.props.selectedFilter, this.props.search);
        var [removed] = list.splice(sourceIndex, 1);
        list.splice(destIndex, 0, removed);
    }

    reorderCards = (list, sourceIndex, destIndex) => {
        var [removed] = FilterProcessor(list, this.props.selectedFilter, this.props.search).splice(sourceIndex, 1);
        list.splice(destIndex, 0, removed);
    }

    moveCard = (sourceList, sourceIndex, destList, destIndex) => {
        var [removed] = FilterProcessor(sourceList, this.props.selectedFilter, this.props.search).splice(sourceIndex, 1);
        destList.splice(destIndex, 0, removed);
    }

    onDragEnd = result => {
        const {source, destination} = result;
        if (!destination) {
            return;
        }

        // reordering
        if (source.droppableId === destination.droppableId) {
            // reordering columns
            if (source.droppableId === 'workspace') {
                this.reorderColumns(source.index, destination.index);
            }
            // reordering cards
            else {
                var column;
                this.props.currentProjectInfo.forEach(element => {
                    if (element.node.id === source.droppableId) {
                        column = element.node.cards.edges;
                        return;
                    }
                });
                if (column) {

                    this.props.moveProjectCard(JSON.parse(JSON.stringify({ source, sourceIndex: source.index, destination, destIndex: destination.index })))


                    this.reorderCards(
                        column,
                        source.index,
                        destination.index);
                        
                }
                else {
                    // TODO: handle missing column
                }
            }
        }
        //moving card
        else {

            var sourceColumn;
            var destColumn;
            this.props.currentProjectInfo.forEach(element => {
                if (element.node.id === source.droppableId) {
                    sourceColumn = element.node.cards.edges;
                }
                if (element.node.id === destination.droppableId) {
                    destColumn = element.node.cards.edges;
                }
            });
            if (sourceColumn && destColumn) {
                this.props.moveProjectCard(JSON.parse(JSON.stringify({source, sourceIndex: source.index, destination, destIndex: destination.index})))
                
                this.moveCard(sourceColumn, source.index, destColumn, destination.index);
            }
            else {
                // TODO: handle missing source/dest
                // this probably means that we are adding a new card from the hopper, did not come up with a case where these would be missing otherwise

                // check if its coming from backlog column

                if (source && source.droppableId && source.droppableId.indexOf("backlog_") >= 0) {

                }

            }
        }
    }

    getColumnsFromFirstIndex(columns) {
        var arr = [];
        for (let i = 1; i < columns.length; i++) {
            arr.push(columns[i])
        }

        return arr;
    }

    render() {

        const _columns = (
            this.props.currentProjectInfo &&  
            this.getColumnsFromFirstIndex(this.props.currentProjectInfo).map((column, index) => {
                return (
                    
                    <Column key={column.node.id} columnKey={column.node.id} column={column} index={index} />
                )
            })
        )

        return (
            <>
                {
                    this.props.currentProject.id ? 
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="workspace" type="COLUMN" direction="horizontal">
                            {(provided, snapshot) => (
                                <div className="layout horizontal app-workspace flex" ref={provided.innerRef} {...provided.droppableProps}>

                                    {
                                        this.state.smallScreen ?
                                            <SwipeableViews style={{width: "100%"}} enableMouseEvents>
                                                {_columns}
                                            </SwipeableViews>
                                            :  
                                            <>

                                            {
                                                        this.props.currentProjectInfo &&
                                                        this.props.currentProjectInfo.map((column, index) => {
                                                            return (

                                                                (index == 0) && <BacklogColumn key={column.node.id} columnKey={column.node.id} column={column} index={index} />
                                                            )
                                                        })
                                            }
                                            {_columns} 
                                            </>
                                    }

                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    :
                    <div className="layout vertical full-height center app-workspace flex vertical-center">
                        {/* TODO: Remove inline styling */}
                        {/* <NoProjectSelectedIcon style={{ width: "222px", height: "167px", stroke: "#176080", fill: "white" }} /> */}
                        <NoCoumnsIcon style={{ width: "222px", height: "167px" }} />
                        <div className="height-10"></div>
                        <span className="title-bold-24">It's time to select a project</span>
                        <div className="height-10"></div>
                        <span className="title-16 opactiy-54 text-center">Add a project to your board from the side drawer </span>
                    </div>

                }
            </>

            

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppWorkspace);
