import {
    UPDATE_HOPPER_STATE,
    GET_PROJECT
} from "../constants/action-types";

import * as Github from "../../github/requests";
import { API, graphqlOperation } from 'aws-amplify';

// There can be a lot of repos linked with the project
// 

export function getIssuesAndPR(dispatch, getState, repos, token, callback) {

    var loadedData = {};
    var page = {};

    // Manage the Callback: Only want to call it once, when all of the data is recieved
    let cbNum = 0;
    let cbData = [];

    const cb = (err, data) => {
        if (err) {
            callback(err);
        } else {
            cbNum -= 1; // Decrement when this callback is called (when data is recieved)
            cbData.push(data);
        }
        // We should have the same number of callback calls, as the number of times we called the `callForData` function
        if (cbNum === 0 && callback) {
            console.log("Calling Callback...");
            callback(null, cbData);
        }
    }

    // Prevents the loading state from being stuck if there are no linked repos
    if (!repos || !repos.length) {
        callback(null, []);
        return;
    }

    repos.forEach( (repo) => {
        if(repo) {
            if (!page[repo.id]) {
                page[repo.id] = 1
            }
    
            if (!loadedData[repo.id]) {
                loadedData[repo.id] = [];
            }

            cbNum += 1; // Increment when calling each time
    
            callForData(dispatch, getState, repo, token, page, loadedData, cb);
        }
    });
}

function callForData(dispatch, getState, repo, token, page, loadedData, callback) {

    Github.getIssuesForRepo(token.eid, repo.owner.login, repo.name, page[repo.id])
        .then(function (dispatch, getState, repo, token, page, loadedData, data){

            var { currentProjectInfo } = getState();

            // Logic here is if your your data is equal to 100, request for data until you get data less 100

            if(data && data.length == 100) {
                page[repo.id]++;
                loadedData[repo.id] = loadedData[repo.id].concat(data)
                callForData(dispatch, getState, repo, token, page, loadedData);
            } else {
                loadedData[repo.id] = loadedData[repo.id].concat(data);
                
                dispatch({ type: UPDATE_HOPPER_STATE, payload: filterExistingCards(loadedData, currentProjectInfo)  });

                dispatch({ type: GET_PROJECT, payload: currentProjectInfo });

                if (callback) {
                    callback(null, data);
                }
            }

        }.bind(null, dispatch, getState, repo, token, page, loadedData))
        .catch((err) => {
            console.error(err);
            if (callback) {
                callback(err);
            }
        })
}

function filterExistingCards(issues, currentProjectInfo) {

    var filteredIssues = [];

    if (currentProjectInfo && currentProjectInfo.length > 0) {

        Object.keys(issues).forEach((id) => {

            for (var k = 0; k < issues[id].length; k++) {
                let issue = issues[id][k];
                var found = false;
                for (let i = 1; i < currentProjectInfo.length; i++) {
                    let column = currentProjectInfo[i];
        
                    if (column.node && column.node.cards && column.node.cards.edges) {
                        var edges = column.node.cards.edges;
                        for (let j = 0; j < edges.length; j++) {
                            let edge = edges[j];
        
                            if (edge.node && edge.node.content) {
                                // this is an issue/pr
                                let issue_pr = edge.node;
        
                                if ((issue.id === issue_pr.content.databaseId) || (issue.pull_request && (issue.pull_request.html_url === issue_pr.content.url))) {
                                    found = true;
                                    break
                                }
                            }
                        }
                    }
        
                    if (found) break;
                }
        
                if (!found) {
                    filteredIssues.push(issue)
                }
            }
        })

    }
    return filteredIssues;
}
