export const GET_CURRENT_USER = ` query User {
    viewer {
        id
        databaseId
        name
        login
        avatarUrl
        viewerCanCreateProjects
        organizations(first: 50) {
            edges {
                node {
                    name
                    id
                    avatarUrl
                    login
                }
            }
        }
    }
}

`;