// The component is inspired from this https://medium.com/dailyjs/quick-redux-tips-for-connecting-your-react-components-e08da72f5b3 article.

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// js

import WorkspaceQuickLinks from "./WorkspaceQuickLinks";
import AppUserRepos from "./AppUserRepos";
import AppWorkspace from "./AppWorkspace";
import RightSidePanel from "./RightSidePanel";
import AddCardsPanel from "./AddCardsPanel";
import ProjectSettings from "./ProjectSettings";

// Dialog Import
import Dialog from '@material-ui/core/Dialog';
import AddColumnDialogContent from "./AddColumnDialogContent";
import AddProjectDialogContent from "./AddProjectDialogContent";

// css
import "../../styles/common.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";

class Home extends React.Component {

    state = {
        smallScreen: false,
        open: false,
        dialog: "",
        column: {},
        org: "",
        editDialog: false,
        projectDialog: false,

    }

    constructor(props, context) {
        super(props, context);
        window.addEventListener("open-column-dialog", this.openColumnDialog);

    }

    openCardsPanel = () => {
        window.dispatchEvent(new CustomEvent('open-add-cards-panel'));
    }

    openColumnDialog = (event) => {
        if (event && event.detail !== null) {
            this.setState({ "column": event.detail });
            this.setState({ "editDialog": true });
        } else {
            this.setState({ "column": {} });
            this.setState({ "editDialog": false });
        }
        this.setState({ "dialog": "column" });
        this.setState({ "open": true });
    };

    addProjectDialog = (org) => {
        this.state.org = org;
        this.setState({
            org: this.state.org
        })
        this.setState({ "dialog": "project" });
        this.setState({ "open": true });
    };

    handleClose = (variable, value) => {
        this.setState({ [variable]: value });
    };

    render() {
        return (
            <Router>

                {/* Dont move this component from this location. Due to re-rendering nature of react things were getting hidden */}

                <Dialog
                    open={this.state.open}
                    onClose={() => this.handleClose("open", false)}
                    classes={{ paper: 'dialog-shell' }}
                >
                    {
                        this.state.dialog === "column" ?
                            <AddColumnDialogContent column={this.state.column} edit={this.state.editDialog} handleDialogClose={() => this.handleClose("open", false)} />
                            : this.state.dialog === "project" ?
                                <AddProjectDialogContent org={this.state.org} handleDialogClose={() => this.handleClose("open", false)} />
                                : ""
                    }
                </Dialog>
                <RightSidePanel />
                <AddCardsPanel />

                <div id="appHome" className="layout vertical full-height">

                    <Switch>

                        <Route
                            exact
                            path="/"
                            component={(props) =>
                                <>
                                    <div className="app-header">
                                        <AppUserRepos addProjectDialog={this.addProjectDialog} />
                                    </div>
                                    <WorkspaceQuickLinks currentProjectInfo={this.props.currentProjectInfo} openCardsPanel={this.openCardsPanel} openColumnDialog={this.openColumnDialog} />
                                    <AppWorkspace />
                                </>
                            }
                        />

                        <Route
                            exact
                            path="/settings"
                            component={(props) =>
                                <>
                                    <ProjectSettings />
                                </>
                            }
                        />

                    </Switch>

                </div>


            </Router>

        );

    }
}

export default Home;    