// Implemented
export const GET_AUTHENTICATED_USER = "GET_AUTHENTICATED_USER";
export const UPDATE_AUTHENTICATED_USER = "UPDATE_AUTHENTICATED_USER";
export const LOGIN_USER = "LOGIN_USER";

// Under Implementation
export const GET_REPOS = "GET_REPOS";
export const GET_PROJECT = "GET_PROJECT";
export const UPDATE_REPOS = "UPDATE_REPOS";
export const CURRENT_REPO_PROECT_INFO = "CURRENT_REPO_PROECT_INFO";
export const SET_CURRENT_REPO = "SET_CURRENT_REPO";
export const SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT"
export const UPDATE_PROJECTS_FOR_ORG = "UPDATE_PROJECTS_FOR_ORG";
export const ALL_PROJECTS_FOR_ALL_ORG = "ALL_PROJECTS_FOR_ALL_ORG";
export const HOPPER_LOADING_STATE = "HOPPER_LOADING_STATE";
export const RECOMMENDED_REPOS = "RECOMMENDED_REPOS";

// NOT Implemented

export const LOADING_INITIAL_DATA = "LOADING_INITIAL_DATA";
export const USER_LOGGED_IN = "USER_LOGGED_IN";

// REPO Related actions
export const WORKSPACE_TYPE = "ALL_REPOS";
export const SET_CURRENT_ORGANIZATION = "SET_CURRENT_ORGANIZATION";
export const SET_CURRENT_ISSUE = "SET_CURRENT_ISSUE";

export const GET_CURRENT_ORGANIZATION = "GET_CURRENT_ORGANIZATION";
export const GET_CURRENT_REPO = "GET_CURRENT_REPO";
export const GET_CURRENT_ISSUE = "GET_CURRENT_ISSUE";


// Errors
export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR";
export const GET_REPOS_ERROR = "GET_REPOS_ERROR";

// UTILS
export const UPDATE_HOPPER_STATE = "UPDATE_HOPPER_STATE";
export const UPDATE_COLUMN_STATE = "UPDATE_COLUMN_STATE";
export const FILTER_FOR_PROJECT = 'FILTER_FOR_PROJECT';
export const SELECTED_FILTER = 'SELECTED_FILTER';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const SEARCH_PROJECT = 'SEARCH_PROJECT';
