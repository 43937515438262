import {

    GET_PROJECT

} from "../constants/action-types";

import { GetSingleColumn } from "../processors/ProjectColumnProcesor";
import { getIssuesAndPR } from "../processors/GetIssuesPullRequestsV3"
import { insert } from "./Utilities";

function isUpdateForSelectedProject(currentProject, payload) {

    if(payload) {
        if (payload.project_url && payload.project_url.indexOf(currentProject.databaseId) >= 0) {
            return true
        }
        console.log("Update does not belong to selected project")
    }
    return false;
}

export function updateColumnsFromWSEvent(payload) {
    return async function (dispatch, getState) {
        const { currentProjectInfo, currentProject, token } = getState();

        if(!currentProject.id) {
            console.warn("No project selected");
            return;
        }

        // Also check if the project selected has some updates or not
        // TODO: Implement notfication system starting from here
        // if(payload)

        console.log(payload, currentProject)
        if (payload.action == "moved") {

            // BY the looks of it, this means that the card has been moved

            if (payload.project_card) {

                if (!isUpdateForSelectedProject(currentProject, payload.project_card)) {
                    return;
                }

                let fromColumnIndex = null;
                let toColumnIndex = null;


                currentProjectInfo.forEach((column, index) => {
                    if (payload.changes && payload.changes.column_id && (column.node.databaseId == payload.changes.column_id.from)) {
                        // Since we have the column, now lets the index of the column before the `moved` column
                        fromColumnIndex = index;
                    }
                    if (payload.project_card && payload.project_card.column_id && (column.node.databaseId == payload.project_card.column_id)) {
                        // Since we have the column, now lets the index of the column before the `movedTo` column
                        toColumnIndex = index;

                    }
                })

                //TODO: Change this to use splicing instead of generating new request

                console.log(fromColumnIndex, toColumnIndex, payload, currentProjectInfo);

                let cardInAction = null;

                if (fromColumnIndex !== null) {
                    if(currentProjectInfo[fromColumnIndex].node.cards) {
                        console.log("cards")
                        let edges = currentProjectInfo[fromColumnIndex].node.cards.edges;

                        for (var i = edges.length; i--; ) {
                            let edge = edges[i]
                            if(edge.node.databaseId === payload.project_card.id) {
                                cardInAction = edge;
                                edges.splice(i, 1)          
                            }
                        }
                    }
                }

                if (toColumnIndex !== null) {
                    // we need to check if the card already exists because the person who initiated the movement WILL have the card moved into its right sport

                    var found = false;

                    if (currentProjectInfo[toColumnIndex].node.cards) {
                        let edges = currentProjectInfo[toColumnIndex].node.cards.edges;

                        for (var i = edges.length; i--;) {
                            let edge = edges[i]
                            if (edge.node.databaseId === payload.project_card.id) {
                                found = true;
                                break;
                            }
                        }
                    }

                    if(!found) {
                        // put the card in at the right spot
                        if (cardInAction === null) {
                            GetSingleColumn(getState, currentProject, currentProjectInfo, toColumnIndex, dispatch)
                        } else {

                            if(payload.project_card.after_id === null) {
                                // put the card at the first place
                                // now if the card is new, it wont exists so better request for new column
                                console.log("Putting that in top spot")
                                currentProjectInfo[toColumnIndex].node.cards.edges.unshift(cardInAction);
    
                            } else {
                                // now this means we have to insert the card after some id
    
                                let edges = currentProjectInfo[toColumnIndex].node.cards.edges;

                                for (var i = edges.length; i--;) {
                                    let edge = edges[i]
                                    if (edge.node.databaseId === payload.project_card.after_id) {
                                        console.log("adding after", payload.project_card.after_id)
                                        edges.splice(i + 1, 0, cardInAction); 
                                        break;
                                    }
                                }

                            }

                        }

                    } else {
                        console.log("This might mean that there are some rearranging going on in the column itself,  try asking for new data");
                        // currentProjectInfo[toColumnIndex].node.cards.edges = [];
                        let refreshColumn = true;
                        GetSingleColumn(getState, currentProject, currentProjectInfo, toColumnIndex, dispatch, undefined, refreshColumn)

                    }


                }

                dispatch({ type: GET_PROJECT, payload: currentProjectInfo });

            } 
            else if (payload.project_column) {

                if (!isUpdateForSelectedProject(currentProject, payload.project_column)) {
                    return;
                }


                let columnToMoveAfterIndex = null;
                let columnToMove = null;
                let arr = []

                /**
                 * This is a 4 step process
                 * 1. We find the column that needs to be moved and splice it and create a new array because the original array is from the state and cannot be modified
                 * 2. We then filter the column from the array we made in step 1
                 * 3. We then find the index of the column to be moved after
                 * 4. We then move it
                */

                // Find the column that needs to be moved

                currentProjectInfo.forEach((column, index) => {
                    if (column.node.databaseId == payload.project_column.id) {
                        columnToMove = JSON.parse(JSON.stringify(column));
                    }
                    arr.push(column);
                })

                // Filter the column that needs to be moved

                arr = arr.filter((item) => item.node.databaseId !== payload.project_column.id)

                // Find the index of column from the filtered array

                arr.forEach((column, index) => {
                    if (column.node.databaseId == payload.project_column.after_id) {
                        columnToMoveAfterIndex = index;
                    }
                })

                // Add it to the right spot 

                let result = null;

                if (payload.project_column.after_id === null) {
                    // arr.unshift(columnToMove);
                    // result = arr;
                    result = insert(arr, 1, columnToMove)
                } else {
                    result = insert(arr, columnToMoveAfterIndex + 1, columnToMove)
                }

                dispatch({ type: GET_PROJECT, payload: result });
            }


            // Now check if this is the selected project or not, if it is update it and if its not show notifications maybe?
        }

        else if (payload.action == "edited") {
            
            // 1. Changing of columns names
            // 2. Changing of notes
            if(payload.project_column) {

                if (!isUpdateForSelectedProject(currentProject, payload.project_column)) {
                    return;
                }

                if( payload.changes ) {
                    // Handling column
                    if(payload.changes.name && payload.changes.name.from) {
    
                        currentProjectInfo.forEach((column, index) => {
                            if(column.node.databaseId === payload.project_column.id) {
                                currentProjectInfo[index].node.name = payload.project_column.name;
                            }
    
                        })
                        dispatch({ type: GET_PROJECT, payload: currentProjectInfo });
    
                    }
    
                }
            }


        }

        else if (payload.action == "created") {
            
            if (payload.project_column) {

                if (!isUpdateForSelectedProject(currentProject, payload.project_column)) {
                    return;
                }

                GetSingleColumn(getState, currentProject, currentProjectInfo, currentProjectInfo.length, dispatch)
            }
            else if (payload.project_card) {
    
                if (!isUpdateForSelectedProject(currentProject, payload.project_card)) {
                    return;
                }

                //TODO: Update this method to use splices instead of querying for new data
                if(currentProject.columns) {
                    console.log("looping over")
                    currentProject.columns.edges && currentProject.columns.edges.forEach((column, index) => {
                        if (column.node.databaseId === payload.project_card.column_id) {
                            console.log("getting new data", column)
                            GetSingleColumn(getState, currentProject, currentProjectInfo, index, dispatch, undefined, true)
                        }
                    })
                }
            }
            
        }

        else if (payload.action == "deleted") {



            if (payload.project_column) {

                if (!isUpdateForSelectedProject(currentProject, payload.project_column)) {
                    return;
                }

                console.log("project column deleted")
                // GetSingleColumn(getState, currentProject, currentProjectInfo, currentProjectInfo.length, dispatch)
            }
            else if (payload.project_card) {
                if (!isUpdateForSelectedProject(currentProject, payload.project_card)) {
                    return;
                }
                //TODO: Update this method to use splices instead of querying for new data
                if (currentProject.columns) {
                    console.log("looping over")
                    currentProject.columns.edges && currentProject.columns.edges.forEach((column, index) => {
                        if (column.node.databaseId === payload.project_card.column_id) {
                            console.log("getting new data", column)
                            GetSingleColumn(getState, currentProject, currentProjectInfo, index, dispatch, undefined, true);
                            getIssuesAndPR(dispatch, getState, currentProject.linkedRepos || [], token)

                        }
                    })
                }
            }
        }
    }
}