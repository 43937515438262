import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MdClear, MdArrowBack, MdCheck } from 'react-icons/md';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import _ from 'lodash';

import { HeaderComponent } from "./HeaderComponent";
import { NormalTextField } from "../styled-components/TextFields/TextFields";
import { TransparentButton } from "../styled-components/Buttons/Buttons";

import { addProjectAndLinkRepos, updateLinkedRepos } from "../../redux/actions/index";
import SwipeableViews from 'react-swipeable-views';
import { searchRepo } from "../../redux/processors/SearchProcessor";

const mapStateToProps = state => {
    return {
        user: state.user,
        repos: state.repos,
        currentProjectsForOrg: state.currentProjectsForOrg
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addProjectAndLinkRepos: (payload, callback) => dispatch(addProjectAndLinkRepos(payload, callback)),
        updateLinkedRepos: (payload, callback) => dispatch(updateLinkedRepos(payload, callback)),
        searchRepo: (queryString, callback) => dispatch(searchRepo(queryString, callback)),
    }
}

const styles = theme => ({
    chip: {
        margin: "2px",
    },
});

class AddProjectDialogContent extends React.Component {
    state = {
        open: false,
        name: "",
        search: "",
        description: "",
        index: this.props.index || 0 ,
        manageRepos: this.props.manageRepos || false,
        repos: [],
        selectedRepos: [],
        savedRepos: this.props.savedRepos || [],
    }

    addProjectAndLinkRepos() {
        this.state.org = this.props.org;
        this.props.addProjectAndLinkRepos(this.state, this.projectResponseCallback);
        // this.props.handleDialogClose();
    }

    addReposToExistingProject = () => {
        console.log("addReposToExistingProject")
        this.state.org = this.props.org;
        this.props.updateLinkedRepos(this.state, this.projectResponseCallback)
    }

    projectResponseCallback (err, data) {
        // New project created
        if(err) {
            // throw an error response
            
            return;
        } 

        this.props.handleDialogClose();
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleChangeIndex = index => {
        this.setState({
            index,
        });
    };

    searchRepo () {
        this.props.searchRepo(this.state.search + " org:" + this.props.org, this.callback)
    }

    callback ( res ) {
        this.setState({ repos: res });
    }

    handleKeyPress = (event) => {
        this.searchRepo();
    }

    handleListItemClick (event, repo) {

        if(repo.selected) {
            // unselect the repo

            this.state.repos.forEach((item, index) => {
                if(item.node.id === repo.node.id) {
                    this.state.repos[index].selected = false;
                }
            })

            this.state.selectedRepos.forEach((item, index) => {
                if(item.node.id === repo.node.id) {
                    this.state.selectedRepos.splice(index, 1)
                }
            })

            this.setState({ selectedRepos: this.state.selectedRepos });
            this.setState({ repos: this.state.repos });



        } else {

            this.state.repos.forEach((item, index) => {
                if (item.node.id === repo.node.id) {
                    this.state.repos[index].selected = true;
                }
            })

            this.state.selectedRepos = this.state.selectedRepos.concat([repo]);
            this.setState({selectedRepos: this.state.selectedRepos});
        }

    }

    checkWithinSelected (repos) {
        this.state.selectedRepos.forEach( (selectedRepo) => {
            repos.forEach((repo, index) => {
                if(repo.node.id === selectedRepo.node.id) {
                    repos[index].selected = true;
                }
            });
        });

        repos = this.removeSavedRepos(repos);
        return repos;
    }

    removeSavedRepos = (repos) => {
        if (!this.state.savedRepos) return repos;
        if (this.state.savedRepos && this.state.savedRepos.length === 0) return repos;

        let arr = [];
        console.log(repos, this.state.savedRepos)
        repos.forEach((repo, index) => {
            let found = false;
            this.state.savedRepos.forEach((savedRepo) => {
                if (repo.node.databaseId === savedRepo.id) {
                    found = true
                }
            })

            if(!found) arr.push(repo)

        })
        console.log("returning", arr)
        return arr;
    }

    constructor(props, context) {
        super(props, context);
        this.addProjectAndLinkRepos = this.addProjectAndLinkRepos.bind(this);
        this.searchRepo = _.debounce(this.searchRepo.bind(this), 300);
        // this.searchRepo = this.searchRepo.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.callback = this.callback.bind(this);
        this.handleListItemClick = this.handleListItemClick.bind(this);
        this.checkWithinSelected = this.checkWithinSelected.bind(this);
        this.projectResponseCallback = this.projectResponseCallback.bind(this);
    }

    render() {

        const { index } = this.state;

        return (
            <div className="layout horizontal dialog-container-override"> 
                <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex}>
                
                    <div className="layout vertical dialog-container dialog-container-override">


                        <HeaderComponent
                            suffix={
                                <IconButton
                                    onClick={this.props.handleDialogClose}
                                >
                                    <MdClear className="icon" />
                                </IconButton>
                            }
                            title={<span className="truncate"> Add Project </span>}
                        />

                        <div className="flex container-overflow-y">

                            <div className="layout horizontal form-margin">
                                <NormalTextField id="newColumnTextField" label="Project name" custommargin="true" placeholder="Enter name" className="flex" value={this.state.name} onChange={this.handleChange('name')} margin="normal" InputLabelProps={{ shrink: true, }} />
                            </div>

                            <div className="layout horizontal form-margin">
                                <NormalTextField id="newColumnTextField" label="Project description" custommargin="true" placeholder="Enter description" className="flex" value={this.state.description} onChange={this.handleChange('description')} margin="normal" InputLabelProps={{ shrink: true, }} />
                            </div>
                            

                        </div>

                        <div className="layout horizontal center container-56">
                            <div className="flex"></div>
                            <TransparentButton disabled={this.state.name ? false : true}  onClick={() => this.setState({index: 1}) }>
                                Next
                            </TransparentButton>
                        </div>

                    </div>

                    <div className="layout vertical dialog-container dialog-container-override">

                        {
                            this.state.manageRepos ? 
                                <HeaderComponent
                                    suffix={
                                        <IconButton
                                            onClick={this.props.handleDialogClose}
                                        >
                                            <MdClear className="icon" />
                                        </IconButton>
                                    }
                                    title={<span className="truncate"> Add Repos </span>}
                                />
                                :
                                <HeaderComponent
                                    icon={
                                        <IconButton onClick={() => this.setState({index: 0})}>
                                            <MdArrowBack className="icon" />
                                        </IconButton>
                                    }
                                    title={<span className="truncate"> Link Repos </span>}
                                />
                        }

                        <div onKeyPress={this.handleKeyPress} className="layout horizontal form-margin">
                            <NormalTextField id="newColumnTextField" label="Repositories" custommargin="true" placeholder="Search ..." className="flex" value={this.state.search} onChange={this.handleChange('search')} margin="normal" InputLabelProps={{ shrink: true, }} />
                        </div>


                        <div className="flex container-overflow-y">

                            <List component="nav">
                                {
                                    this.checkWithinSelected(this.state.repos).map((repo) => {
                                        return (
                                            <ListItem
                                                key={repo.cursor}
                                                button
                                                selected={this.state.selectedIndex === 0}
                                                onClick={event => this.handleListItemClick(event, repo)}
                                            >
                                            
                                            { repo.selected ? <MdCheck /> : "" }

                                                <span>{repo.node.name}</span>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </div>

                        <div className="layout horizontal center container-56">
                            <div className="flex"></div>
                            {
                                this.state.selectedRepos ?
                                <TransparentButton disabled={this.state.selectedRepos && this.state.selectedRepos.length ? false : true} onClick={() => this.addReposToExistingProject()}>
                                    Add
                                </TransparentButton>
                                : 
                                <TransparentButton disabled={this.state.selectedRepos && this.state.selectedRepos.length ? false : true} onClick={() => this.addProjectAndLinkRepos()}>
                                    Create
                                </TransparentButton>

                            }
                        </div>

                    </div>



                </SwipeableViews>
            </div>

        )
    }

}

AddProjectDialogContent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddProjectDialogContent))


