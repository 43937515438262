export const UpdateColumn = `
    mutation updateColumn($cardId: ID!, $afterCardId: ID, $columnId: ID!) {
        moveProjectCard(input: {
            cardId: $cardId,
            columnId: $columnId,
            afterCardId: $afterCardId,
        }) {
            cardEdge {
                node {
                    column {
                        createdAt
                    }
                }
            }
        }
    }
`

export const AddProjectColumn = `
    mutation addProjectColumn($projectId: ID!, $name: String!) {
        addProjectColumn(input: {
            projectId: $projectId,
            name: $name
        }) {
            columnEdge {
                node {
                    name 
                    createdAt
                }
            }
        }
    }
`

export const UpdateProjectColumn = `
    mutation updateProjectColumn($projectColumnId: ID!, $name: String!) {
        updateProjectColumn(input: {
            projectColumnId: $projectColumnId,
            name: $name
        }) {
            clientMutationId
        }
    }
`

export const AddProject = `
    mutation addProject($ownerId: ID!, $name: String!, $body: String) {
        createProject(input: {
            ownerId: $ownerId,
            name: $name,
            body: $body
        }) {
            clientMutationId
            project {
                id
                databaseId
            }
        }
    }
`