import React from 'react';
import { connect } from "react-redux";
import { MdAdd } from 'react-icons/md';

import { TransparentButton } from "../styled-components/Buttons/Buttons";


const mapStateToProps = state => {
    return {
        currentProject: state.currentProject,
    };
};

class WorkspaceQuickLinks extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <>
                {
                    this.props.currentProject.id ?
                        <div className="layout horizontal" style={{ height: "32px", backgroundColor: "var(--app-workspace-background-color)", borderBottom: "0.5px solid rgba(0,0,0,0.16)" }}>
                            <div className="flex"></div>
                            {/* <TransparentButton onClick={() => this.props.openCardsPanel()} override="true">
                                <div className="layout horizontal center" >
                                    <MdAdd style={{ marginRight: "4px", marginBottom: "1.5px" }} />Add card
                                            </div>
                            </TransparentButton> */}
                            <TransparentButton onClick={() => this.props.openColumnDialog()} override="true">
                                <div className="layout horizontal center" >
                                    <MdAdd style={{ marginRight: "4px", marginBottom: "1.5px" }} />Add column
                                            </div>
                            </TransparentButton>
                        </div>
                        : ""

                }
            </>
        )
    }


}

export default connect(mapStateToProps, null)(WorkspaceQuickLinks);