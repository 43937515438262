export const GET_PROJECTS_FOR_USER_QUERY = `

    query GetProjectFromUser {
        viewer {
            projects(first: 50) {
                totalCount

                nodes {
                    databaseId
                    number
                    name
                    id

                    columns(first: 10) {
                        totalCount
                        edges {
                            cursor
                            node {
                                databaseId
                                id
                                name
                                cards(first: 1) {
                                    totalCount
                                }
                            }
                        }
                    }                       
                }
            }

        }
    }
    
`