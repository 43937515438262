import React from 'react';
import { connect } from "react-redux";
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { MdAddCircleOutline, MdExitToApp, MdFilterList, MdSettings } from 'react-icons/md';
import Badge from '@material-ui/core/Badge';
import Filter from './Filter';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import Help from '@material-ui/icons/Help';
import { Link } from "react-router-dom";

import Popover from '@material-ui/core/Popover';

// js
import { 
    setCurrentWorkspaceProject, 
    logOutFunc, 
    calculateFilterDataset, 
    updateSelectedFilter,
    searchProject } from "../../redux/actions/index";

import { debounce } from "debounce";

// css
import "../../styles/common.css";
import "../../styles/common.nav-bar-2.css";
import "../../styles/common.filter.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";

const styles = {
    root: {
        padding: '0',
        margin: "0 16px",
        display: 'flex',
        alignItems: 'center',
        width: 480,
    },
    listRoot: {
        padding: 0
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 0,
    },
};

const popoverStyles = {
    container: {
        "maxWidth": "280px",
    },
    code: {
        "backgroundColor": "#eee"
    }
};

const mapStateToProps = state => {
    return {
        user: state.user,
        repos: state.repos,
        currentProject: state.currentProject,
        currentProjectsForOrg: state.currentProjectsForOrg,
        filter: state.filter, 
        orgsProjects: state.orgsProjects,
        recommendedReposPresent: state.recommendedRepos && state.recommendedRepos.length > 0,
        recommendedReposNum: state.recommendedRepos && state.recommendedRepos.length
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentProject: repo => dispatch(setCurrentWorkspaceProject(repo)),
        logOutFunc: () => dispatch(logOutFunc()),
        calculateFilterDataset: () => dispatch(calculateFilterDataset()),
        updateSelectedFilter: (filter) => dispatch(updateSelectedFilter(filter)),
        searchProject: (value) => dispatch(searchProject(value)),
    }
}

class AppUserRepos extends React.Component {

    state = {
        user: {},
        left: false,
        showDot: false,
        smallScreen: false,
        showPopover: false

    }

    constructor(props, context) {
        super(props, context);
        this.handleProjectClick = this.handleProjectClick.bind(this);
        this.searchProject = debounce(this.searchProject, 200)
    }

    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };


    handleProjectClick = (repo, org) => {
        this.setState({
            left: false,
        });
        repo.org = org;
        this.props.setCurrentProject(repo);
    }

    handleClick = (event, element) => {
        this.setState({ [element]: event.currentTarget });
    }

    openThemePanel () {
        window.dispatchEvent(new CustomEvent('open-theme-panel'));
    }

    openFilterPopover = event => {
        this.props.calculateFilterDataset()
        this.setState({
            filterAnchor: event.currentTarget,
        });
    };

    handleFilterClose = () => {
        this.setState({
            filterAnchor: null,
        });
    };

    updateSelectedFilter = (filter) => {
        this.props.updateSelectedFilter(filter)
        if(Object.keys(filter).length > 0) {
            this.state.showDot = true;
        } else {
            this.state.showDot = false;
        }
        this.setState({
            showDot: this.state.showDot
        })
    }

    checkWidth = () => {
        const match = window.matchMedia(`(max-width: 768px)`).matches;
        if (match == this.state.smallScreen) return;
        if (match) {
            this.setState({ smallScreen: true });
        } else {
            this.setState({ smallScreen: false });
        }
    }

    componentDidMount() {
        this.checkWidth();
        window.addEventListener("resize", this.checkWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkWidth);
    }

    searchProject = (value) => {
        value = value ? value.toLowerCase() : "";
        this.props.searchProject(value)
    }

    searchChange = (e) => {
        this.searchProject(e.target.value)
    }

    togglePopover = (e) => {
        e.stopPropagation();
        this.setState({
            showPopover: !this.state.showPopover,
            anchorEl: e.target
        });
    }

    popoverClose = (e) => {
        this.setState({
            showPopover: false,
            anchorEl: null
        });
    }

    render() {

        const { filterAnchor } = this.state;
        const { showDot } = this.state;
        const { classes } = this.props;

        if(this.props.user) {
            return (
                // <div className="layout vertical full-height">
                <>

                    <div className="layout horizontal center" >
                        <div onClick={this.toggleDrawer('left', true)} className="layout horizontal center center-justified profile-image-container cursor-pointer">
                            <img src={process.env.PUBLIC_URL + '/images/krum-gear5.svg'}></img>
                        </div>
                        <span className="truncate">{this.props.currentProject && this.props.currentProject.name}</span>

                        <div className="flex"></div>
                        {
                            this.props.currentProject && this.props.currentProject.id ?
                            <>
                            {
                                this.state.smallScreen ? ""
                                :
                                <div className="layout horizontal add-cards-search-container">
                                    <Popover 
                                        open={this.state.showPopover} 
                                        onClose={this.popoverClose} 
                                        anchorEl={this.state.anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <div className="margin-16 container-overflow-y" style={popoverStyles.container}>
                                            <p>To search for milestones by creation date, use the Search Bar by typing any of the following...</p>
                                            <p><code style={popoverStyles.code}>milestone/milestones/ms:</code></p>
                                            <ul>
                                                <li><code style={popoverStyles.code}>after [DATE]</code> to search for milestones created after the given date</li>
                                                <li><code style={popoverStyles.code}>before [DATE]</code> to search for milestones created before the given date</li>
                                                <li><code style={popoverStyles.code}>[DATE] to [DATE]</code> to search for milestones created within the given date range</li>
                                            </ul>
                                            <p> When entering a date, please use one of the following formats: </p>
                                            <ul>
                                                <li><code style={popoverStyles.code}>MM/DD/YYYY</code></li>
                                                <li><code style={popoverStyles.code}>MM-DD-YYYY</code></li>
                                            </ul>
                                        </div>
                                    </Popover>
                                    <Paper className={classes.root} elevation={1}>
                                        <InputBase onChange={this.searchChange} className={classes.input} placeholder="Search project..." />

                                        <IconButton className={classes.iconButton} aria-label="Help" onClick={this.togglePopover}>
                                            <Help />
                                        </IconButton>

                                        <IconButton className={classes.iconButton} aria-label="Search">
                                            <SearchIcon />
                                        </IconButton>
                                    </Paper>
                                </div>

                            }
                                <div className="layout horizontal center center-justified filter-icon-container">
                                    <IconButton onClick={this.openFilterPopover}>
                                        {
                                            showDot ? 
                                            <Badge color="secondary"  variant="dot">
                                                <MdFilterList className="icon" />
                                            </Badge>
                                            :
                                            <MdFilterList className="icon" />
                                        }
                                    </IconButton>
                                </div>

                                <Filter anchor={filterAnchor} filter={this.props.filter} onClose={this.handleFilterClose} updateSelectedFilter={this.updateSelectedFilter} />

                                {
                                    this.props.currentProject 
                                    && this.props.currentProject.id ?
                                    <Link to="/settings" className="layout horizontal center center-justified profile-image-container">
                                        <IconButton title="Project Settings">
                                        {
                                            this.props.recommendedReposPresent ? 
                                            <Badge color="secondary" badgeContent={this.props.recommendedReposNum}>
                                                <MdSettings className="icon" />
                                            </Badge>
                                            :
                                            <MdSettings className="icon" />
                                        }
                                        </IconButton>
                                    </Link>
                                    : ""
                                }
                            </>
                            :
                            <>
                            </>

                        }

                        <div className="layout horizontal center center-justified profile-image-container">
                            <IconButton onClick={this.props.logOutFunc} title="Log out">
                                <MdExitToApp className="icon" />
                            </IconButton>
                        </div>

                    </div>

                    {/* Hear the search bar would go */}

                    <SwipeableDrawer 
                        open={this.state.left} 
                        onClose={this.toggleDrawer('left', false)}
                        onOpen={this.toggleDrawer('left', true)}
                        ModalProps={{
                            BackdropProps: {
                                invisible: true
                            }
                        }}
                    >
                        
                        <div className="app-nav-bar-2 flex container-overflow-y">

                            <div className="layout horizontal center" style={{ height: "64px", padding: "0 12px", paddingRight: 0}}>
                                <div className="layout vertical flex">
                                    <span style={{ fontSize: "20px"}}>{this.props.user && this.props.user.name}</span>
                                    <span style={{ fontSize: "14px" }}>{this.props.user && this.props.user.login}</span>
                                </div>
                                <div className="layout horizontal center center-justified profile-image-container">
                                    <img src={this.props.user.avatarUrl}></img>
                                </div>
                            </div>

                            {
                                this.props.orgsProjects && Object.keys(this.props.orgsProjects).map((org) => {
                                    return (
                                        <div key={org}>
                                            <div className="layout horizontal center" style={{ paddingRight: "12px"}}>
                                                <div className="layout horizontal center center-justified profile-image-container">
                                                    <img src={this.props.orgsProjects[org].creds.avatarUrl}></img>
                                                </div>                                            
                                                <span>{this.props.orgsProjects[org].creds.name}</span>
                                                <div className="flex"></div>
                                                <MdAddCircleOutline className="cursor-pointer" onClick={() => this.props.addProjectDialog(org)} />
                                            </div>
                                            {
                                                this.props.orgsProjects[org].projects && 
                                                this.props.orgsProjects[org].projects.nodes && 
                                                this.props.orgsProjects[org].projects.nodes.length ?
                                                this.props.orgsProjects[org].projects.nodes.map((project) => {
                                                    return (
                                                        <div onClick={() => this.handleProjectClick(project, org)} key={project.id} className="layout horizontal repo-title-container truncate">
                                                            <span title={project.name} className="repo-title truncate">{project.name}</span>
                                                        </div>

                                                    )
                                                })

                                                :
                                                <span style={{ padding: " 0 16px", fontSize: "12px"}}>No projects found</span>
                                            }
                                        </div>
                                    )
                                })
                            }
                        
                        </div>

                    </SwipeableDrawer>


                
                {/* </div> */}
                </>
            );

        } 
        // else {
        //     return (

        //     <div className="layout vertical full-height center vertical-center">
        //         <img className="app-logo" src={process.env.PUBLIC_URL + '/images/krum-gear5.svg'}></img>

        //     </div>
        //     );
        // }


    }
}

// connect(mapStateToProps, mapDispatchToProps)(App)
AppUserRepos.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppUserRepos));    
