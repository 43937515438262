import React from "react";

const NoColumnsIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 222 169" {...props}>
    <defs>
      <path id="icn-crane_svg__a" d="M0 168h252V0H0z" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-15 -1)">
      <path
        fill="#F2F6F8"
        d="M227.654 102c0-55.622-45.13-100.714-100.8-100.714-55.67 0-100.8 45.092-100.8 100.714a100.21 100.21 0 0 0 8.981 41.545h183.638A100.25 100.25 0 0 0 227.654 102"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M28.5 145.5h195m-207 0h6m207 0h6"
      />
      <path
        fill="#F2F6F8"
        d="M224.337 165.39c0 2.259-15.269 4.09-34.1 4.09-18.835 0-34.102-1.831-34.102-4.09 0-2.258 15.267-4.089 34.101-4.089 18.832 0 34.1 1.83 34.1 4.09m-109.124-.001c0 2.259-15.266 4.09-34.1 4.09-18.832 0-34.1-1.831-34.1-4.09 0-2.258 15.268-4.089 34.1-4.089 18.834 0 34.1 1.83 34.1 4.09"
      />
      <path
        fill="#FFF"
        d="M53.095 143.584a4.72 4.72 0 0 1-4.72-4.716V75.441L163.5 76.5l.818 62.368a4.718 4.718 0 0 1-4.72 4.716H53.095z"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M159 145.5H54c-2.606 0-4.5-1.897-4.5-4.5V76.5h114V141c0 2.603-1.892 4.5-4.5 4.5z"
      />
      <path
        fill="#FFF"
        d="M164.318 75.44H48.375V64.346a5.261 5.261 0 0 1 5.265-5.26h105.414a5.261 5.261 0 0 1 5.264 5.26V75.44z"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M163.5 76.5h-114V63c0-2.906 1.592-4.5 4.5-4.5h105c2.908 0 4.5 1.594 4.5 4.5v13.5z"
      />
      <path
        fill="#E0F0F9"
        d="M59.288 67.263a2.728 2.728 0 0 1-5.456 0 2.728 2.728 0 0 1 5.456 0"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M61.5 67.5c0 1.506-1.493 3-3 3s-3-1.494-3-3 1.493-3 3-3 3 1.494 3 3z"
      />
      <path
        fill="#E0F0F9"
        d="M73.2 67.263a2.728 2.728 0 1 1-5.456-.002 2.728 2.728 0 0 1 5.455.002"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M73.5 67.5c0 1.506-1.49 3-3 3-1.504 0-3-1.494-3-3s1.496-3 3-3c1.51 0 3 1.494 3 3z"
      />
      <path
        fill="#E0F0F9"
        d="M83.458 68.727a2.728 2.728 0 0 1-5.458 0 2.728 2.728 0 0 1 5.458 0"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M85.5 67.5c0 1.506-1.493 3-3 3s-3-1.494-3-3 1.493-3 3-3 3 1.494 3 3z"
      />
      <path
        fill="#FFF"
        d="M81.654 161.706l-24.038-24.018a1.259 1.259 0 0 1 0-1.78l2.076-2.075a1.262 1.262 0 0 1 1.782 0l24.038 24.018a1.26 1.26 0 0 1 0 1.78l-2.077 2.075a1.262 1.262 0 0 1-1.781 0"
      />
      <path
        stroke="#176080"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M81.654 161.706l-24.038-24.018a1.259 1.259 0 0 1 0-1.78l2.076-2.075a1.262 1.262 0 0 1 1.782 0l24.038 24.018a1.26 1.26 0 0 1 0 1.78l-2.077 2.075a1.262 1.262 0 0 1-1.781 0z"
      />
      <path
        fill="#E0F0F9"
        d="M108.204 161.706l-24.039-24.018a1.259 1.259 0 0 1 0-1.78l2.077-2.075a1.262 1.262 0 0 1 1.782 0l24.038 24.018a1.26 1.26 0 0 1 0 1.78l-2.077 2.075a1.262 1.262 0 0 1-1.781 0"
      />
      <path
        stroke="#176080"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M108.204 161.706l-24.039-24.018a1.259 1.259 0 0 1 0-1.78l2.077-2.075a1.262 1.262 0 0 1 1.782 0l24.038 24.018a1.26 1.26 0 0 1 0 1.78l-2.077 2.075a1.262 1.262 0 0 1-1.781 0z"
      />
      <path
        fill="#FFF"
        d="M61.474 161.706l24.038-24.018a1.259 1.259 0 0 0 0-1.78l-2.076-2.075a1.262 1.262 0 0 0-1.782 0l-24.038 24.018a1.255 1.255 0 0 0 0 1.78l2.074 2.075a1.265 1.265 0 0 0 1.784 0"
      />
      <path
        stroke="#176080"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M61.474 161.706l24.038-24.018a1.259 1.259 0 0 0 0-1.78l-2.076-2.075a1.262 1.262 0 0 0-1.782 0l-24.038 24.018a1.255 1.255 0 0 0 0 1.78l2.074 2.075a1.265 1.265 0 0 0 1.784 0z"
      />
      <path
        fill="#FFF"
        d="M87.04 162.664h-3.673a.893.893 0 0 1-.892-.89v-36.381c0-.491.4-.89.892-.89h3.674c.491 0 .892.399.892.89v36.38c0 .491-.4.891-.892.891"
      />
      <path
        stroke="#176080"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M87.04 162.664h-3.673a.893.893 0 0 1-.892-.89v-36.381c0-.491.4-.89.892-.89h3.674c.491 0 .892.399.892.89v36.38c0 .491-.4.891-.892.891z"
      />
      <path
        fill="#FFF"
        d="M59.76 162.664h-3.674a.893.893 0 0 1-.892-.89v-36.381c0-.491.4-.89.892-.89h3.674c.492 0 .892.399.892.89v36.38c0 .491-.4.891-.892.891"
      />
      <path
        stroke="#176080"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M59.76 162.664h-3.674a.893.893 0 0 1-.892-.89v-36.381c0-.491.4-.89.892-.89h3.674c.492 0 .892.399.892.89v36.38c0 .491-.4.891-.892.891z"
      />
      <path
        fill="#FFF"
        d="M81.216 134.044H61.91a1.26 1.26 0 0 1-1.26-1.26v-2.932a1.26 1.26 0 0 1 1.26-1.26h19.306c.695 0 1.26.565 1.26 1.26v2.933a1.26 1.26 0 0 1-1.26 1.259"
      />
      <path
        stroke="#176080"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M81.216 134.044H61.91a1.26 1.26 0 0 1-1.26-1.26v-2.932a1.26 1.26 0 0 1 1.26-1.26h19.306c.695 0 1.26.565 1.26 1.26v2.933a1.26 1.26 0 0 1-1.26 1.259z"
      />
      <path
        fill="#E0F0F9"
        d="M60.443 117.689a2.518 2.518 0 0 1-2.52-2.518L58.5 88.5c0-1.39 1.609-3 3-3h21c1.391 0 3 3.11 3 4.5v25.5c0 1.392-.06 2.189-1.452 2.189H60.443z"
      />
      <path
        stroke="#176080"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M82.5 118.5h-21c-1.391 0-3-1.608-3-3v-27c0-1.39 1.609-3 3-3h21c1.391 0 3 1.61 3 3v27c0 1.392-1.609 3-3 3z"
      />
      <path
        fill="#E0F0F9"
        d="M109.5 127.5c-1.394 0-3 .11-3 1.5v16.5H150l.678-17.115c0-1.39-.787-.885-2.178-.885h-39z"
      />
      <path
        stroke="#176080"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M148.5 127.5h-39c-1.394 0-3 1.61-3 3v15h45v-15c0-1.39-1.609-3-3-3z"
      />
      <mask id="icn-crane_svg__b" fill="#fff">
        <use xlinkHref="#icn-crane_svg__a" />
      </mask>
      <path
        fill="#E0F0F9"
        d="M105.666 119.05l42.834-.55v-6l-42.834-.265z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M106.5 118.5h42v-6h-42z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        fill="#FFF"
        d="M173.142 45.242l-3.249 11.361h22.738v-11.36z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        fill="#E0F0F9"
        d="M217.724 45.242H114.658a2.52 2.52 0 0 1-2.52-2.517v-1.456a2.518 2.518 0 0 1 2.52-2.518h103.066a2.518 2.518 0 0 1 2.52 2.518v1.456a2.52 2.52 0 0 1-2.52 2.517"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M217.5 46.5h-102c-1.391 0-3-1.61-3-3v-3c0-1.392 1.609-3 3-3h102c1.391 0 3 1.608 3 3v3c0 1.39-1.609 3-3 3z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        fill="#E0F0F9"
        d="M176.39 162.119h12.993V63.094H176.39z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M176.39 162.119h12.993V63.094H176.39z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        fill="#F2F6F8"
        d="M189.384 38.751H176.39l5.19-16.864c.397-1.282 2.213-1.282 2.61 0l5.193 16.864z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M189.493 37.5H176.5l5.082-15.613c.396-1.282 2.212-1.282 2.608 0l5.303 15.613zm-74.626 1.251l68.2-17.008M211.5 37.5l-28.43-15.757"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        fill="#E0F0F9"
        d="M171.257 63.094h20.011c.753 0 1.363-.612 1.363-1.365v-5.126h-22.738v5.126c0 .753.61 1.365 1.364 1.365"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M171.5 63.5H191c.753 0 1.5-.747 1.5-1.5v-5.5h-22v5c0 .753.247 2 1 2zm1.51-17l-2.51 10h22v-10z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        fill="#F2F6F8"
        d="M121.885 51.733h12.993v-6.49h-12.993z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M121.885 51.733h12.993v-6.49h-12.993zM130.5 85.5c0 1.793-1.206 3-3 3-1.792 0-3-1.207-3-3s1.208-3 3-3c1.794 0 3 1.207 3 3zm45.89 8.426l12.993 12.985m.001-12.985l-12.993 12.985m-.001 1.622l12.993 12.982m.001-12.982l-12.993 12.982m-.001.363l12.993 12.983m.001-12.983l-12.993 12.983m-.001.646l12.993 12.984m.001-12.984L176.39 148.49m0-69.17l12.993 12.983m.001-12.983L176.39 92.304m0-27.589l12.993 12.982m.001-12.982L176.39 77.697"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        fill="#FFF"
        d="M167.96 156.667h45.222c.695 0 1.26.564 1.26 1.26v4.192H166.7v-4.193a1.26 1.26 0 0 1 1.26-1.259"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M167.96 156.667h45.222c.695 0 1.26.564 1.26 1.26v4.192H166.7v-4.193a1.26 1.26 0 0 1 1.26-1.259z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        fill="#FFF"
        d="M174.78 151.216h15.214c.693 0 1.26.564 1.26 1.26v4.191H173.52v-4.192a1.26 1.26 0 0 1 1.26-1.259"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M174.78 151.216h15.214c.693 0 1.26.564 1.26 1.26v4.191H173.52v-4.192a1.26 1.26 0 0 1 1.26-1.259z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        fill="#E0F0F9"
        d="M198.075 156.668h13.64v-5.451h-13.64z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M198.075 156.668h13.64v-5.451h-13.64z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        fill="#E0F0F9"
        d="M198.075 151.217h13.64v-5.454h-13.64z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M198.075 151.217h13.64v-5.454h-13.64z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        fill="#E0F0F9"
        d="M198.075 145.763h13.64v-5.451h-13.64z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M198.075 145.763h13.64v-5.451h-13.64zM127.5 88.5l10.556 8.746-19.556.254z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        fill="#E0F0F9"
        d="M110.429 104.06h36v-6.816h-36z"
        mask="url(#icn-crane_svg__b)"
      />
      <path
        stroke="#176080"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M109.5 103.5h36v-6h-36zm18-21v-30"
        mask="url(#icn-crane_svg__b)"
      />
    </g>
  </svg>
);

export default NoColumnsIcon;

