import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import { MdClear, MdArrowBack, MdCheck } from 'react-icons/md';

import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import SwipeableViews from 'react-swipeable-views';
import InfiniteScroll from 'react-infinite-scroller';

// Local components
import { HeaderComponent } from "./HeaderComponent";

import "../../styles/common.css";
import "../../styles/common.right-panel.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";

import { setCurrentWorkspaceProject, getIssuesForRepo } from "../../redux/actions/index";

const styles = {
    RightSideDrawer: {
        backgroundColor: "var(--app-workspace-background-color)",
    },
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    listRoot: {
        padding: 0
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 8,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },


};

const mapStateToProps = state => {
    return {
        currentProject: state.currentProject,
        currentProjectInfo: state.currentProjectInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentProject: repo => dispatch(setCurrentWorkspaceProject(repo)),
        getIssuesForRepo: (repo, page, callback, per_page) => dispatch(getIssuesForRepo(repo, page, callback, per_page)),
    }
}

class AddCardsPanel extends React.Component {

    state = {
        index: 0,
        issues: [],
        page: 1,
        per_page: 100,
        cardsLength: 0,
        selectedRepo: {},
        selectedIssues: [],
        right: false,
        hasMoreItems: false, 
        repoSelected: false, 
    }

    constructor(props, context) {
        super(props, context);
        this.selectRepo = this.selectRepo.bind(this);
        this.issueCallback = this.issueCallback.bind(this);
        this.filterExistingCards = this.filterExistingCards.bind(this);

        window.addEventListener("open-add-cards-panel", (event) => {
            this.toggleDrawer('right', !this.state.right)();
        });

    }

    selectRepo (repo) {
        this.state.page = 1;
        this.setState({ issues: [] });
        this.setState( {
            page: this.state.page
        });
        this.props.getIssuesForRepo(repo, this.state.page, this.issueCallback, this.state.per_page);
        this.setState({ selectedRepo: repo});
        this.setState({ index: 1 })
    }

    issueCallback = (err, data) => {
        // console.log("inside issue callback", err, data);
        if(err) {
            // TODO error fix
        } else {

            if(data) {
                if(data.length < this.state.per_page) {
                    this.setState({ hasMoreItems: false })
                } else {
                    this.setState({ hasMoreItems: true})
                }
                this.state.issues = this.state.issues.concat(data);

                var filteredIssues = this.filterExistingCards(this.state.issues)
                this.setState({ cardsLength: filteredIssues.length })

                this.setState({
                    issues: filteredIssues
                })
            } else {
                this.setState({ hasMoreItems: false})
            }

        }
    }

    filterExistingCards (issues) {
        if (this.state.selectedRepo && !this.state.selectedRepo.id) {
            return []
        }

        var filteredIssues = [];

        if (this.props.currentProjectInfo && this.props.currentProjectInfo.length > 0) {

            for (let k = 0; k < issues.length; k++) {
                let issue = issues[k];
                var found = false;


                for (let i = 0; i < this.props.currentProjectInfo.length; i++) {
                    let column = this.props.currentProjectInfo[i];

                    if (column.node && column.node.cards && column.node.cards.edges) {
                        var edges = column.node.cards.edges;
                        for (let j = 0; j < edges.length; j++) {
                            let edge = edges[j];

                            if (edge.node.content) {
                                // this is an issue/pr
                                let issue_pr = edge.node;

                                if (issue.id === issue_pr.content.databaseId) {
                                    found = true;
                                    break
                                }
                            }
                        }
                    }

                    if(found) break;
                }
    
                if (!found) {
                    filteredIssues.push(issue)
                }
            }

        }
        return filteredIssues;
    }


    handleListItemClick(event, repo) {

        if (repo.selected) {
            // unselect the repo

            this.state.issues.forEach((item, index) => {
                if (item.id === repo.id) {
                    this.state.issues[index].selected = false;
                }
            })

            this.state.selectedIssues.forEach((item, index) => {
                if (item.id === repo.id) {
                    this.state.selectedIssues.splice(index, 1)
                }
            })

            this.setState({ selectedIssues: this.state.selectedIssues });
            this.setState({ repos: this.state.issues });



        } else {

            this.state.issues.forEach((item, index) => {
                if (item.id === repo.id) {
                    this.state.issues[index].selected = true;
                }
            })

            this.state.selectedIssues = this.state.selectedIssues.concat([repo]);
            this.setState({ selectedIssues: this.state.selectedIssues });
        }

        console.log("handleListItemClick", repo)
        console.log(this.state.selectedIssues)
    }

    checkWithinSelected(repos) {
        this.state.selectedIssues.forEach((selectedRepo) => {
            repos.forEach((repo, index) => {
                if (repo.id === selectedRepo.id) {
                    repos[index].selected = true;
                }
            })

        })
        return repos;
    }

    
    toggleDrawer = (side, open) => () => {
        document.querySelector("#appHome").style.width = open ? "calc(100% - 588px)" : "100%"
        this.setState({
            [side]: open,
        });
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleChangeIndex = index => {
        this.setState({
            index,
        });
    };

    loadMore = () => {
        this.state.page++;
        this.props.getIssuesForRepo(this.state.selectedRepo, this.state.page, this.issueCallback, this.state.per_page);
    }


    render() {

        const { index } = this.state;
        const { classes } = this.props;

        return (
            <Drawer
                {...(this.state.right ? { variant: 'permanent' } : {})}
                ModalProps={{
                    BackdropProps: {
                        invisible: true
                    }
                }}
                id="rightPanel"
                PaperProps={{
                    elevation: 1,
                }}
                classes={{ paper: 'right-panel' }}
                anchor="right" open={this.state.right}
                onClose={this.toggleDrawer('right', false)}
            >
                <div tabIndex={0} ref={this.drawerRoot} id="drawerRoot" className={classes.RightSideDrawer + " layout vertical full-height right-panel"} role="button">

                    <SwipeableViews className="full-height" index={index} onChangeIndex={this.handleChangeIndex}>

                        <div className="layout vertical full-height">
                            <HeaderComponent
                                icon={
                                    <IconButton onClick={this.toggleDrawer('right', false)}>
                                        <MdClear className="icon" />
                                    </IconButton>
                                }
                                title={<span className="truncate"> Select a repository </span>}
                            />

                            <div className="flex container-overflow-y" style={{ backgroundColor: "var(--app-workspace-background-color)" }}>

                                <List className={classes.listRoot} component="nav">

                                    {
                                        this.props.currentProject && this.props.currentProject.linkedRepos && this.props.currentProject.linkedRepos.map((repo) => {
                                            return (
                                                <div className="cursor-pointer" key={repo.id}>
                                                    <Paper onClick={() =>  this.selectRepo(repo) } elevation={0}>
                                                        <ListItem>
                                                            <span>{repo.name}</span>
                                                        </ListItem>
                                                    </Paper>
                                                    <Divider />
                                                </div>

                                            )
                                        })
                                    }

                                </List>
                            </div>
                        </div>

                        <div className="layout vertical full-height">
                            <HeaderComponent
                                icon={
                                    <IconButton onClick={() => this.setState({ index: 0 })}>
                                        <MdArrowBack className="icon" />
                                    </IconButton>
                                }
                                title={<span className="truncate"> Add card to columns </span>}
                            />

                            <div className="flex" style={{ backgroundColor: "var(--app-workspace-background-color)" }}>

                                <div className="layout vertical add-cards-container full-height">

                                    <div className="layout horizontal add-cards-search-container">
                                        <Paper className={classes.root} elevation={1}>
                                            <InputBase className={classes.input} placeholder="Search cards..." />

                                            <IconButton className={classes.iconButton} aria-label="Search">
                                                <SearchIcon />
                                            </IconButton>
                                        </Paper>
                                    </div>

                                    <div className="height-10"></div>
                                    <div ref={(ref) => this.scrollParentRef = ref} className="flex container-overflow-y">

                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={this.loadMore}
                                            hasMore={this.state.hasMoreItems}
                                            loader={<div key={0}>Loading ...</div>}
                                            useWindow={false}
                                            getScrollParent={() => this.scrollParentRef}
                                        >
                                            <List className={classes.listRoot} component="nav">

                                                {
                                                    this.state.issues && this.state.issues.map((issue) => {
                                                        return (
                                                            <div key={issue.id}>
                                                                <Paper elevation={0}>
                                                                    <ListItem onClick={event => this.handleListItemClick(event, issue)} className="layout horizontal" button={true}>
                                                                       
                                                                        {issue.selected ? <MdCheck /> : ""}

                                                                        <span className=" flex" title={issue.title} style={{ fontSize: "14px" }}>  <a target="_blank" href={issue.html_url}> #{issue.number}</a> &nbsp; {issue.title}</span>

                                                                        <div className="AssigneeContainer layout horizontal center">
                                                                            {
                                                                                issue.assignees && issue.assignees.map((assignee, index, numAssignees) => {
                                                                                    if (index < 2) {
                                                                                        return (
                                                                                            <img key={assignee.id} className="Assignee" src={assignee.avatar_url} />
                                                                                        )
                                                                                    }
                                                                                    else if (index === 2 && issue.assignees.length > 3) {
                                                                                        return (
                                                                                            <div key={assignee.id} className="OverflowAssignee">
                                                                                                +{issue.assignees.length - 2}
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    else if (index === 2) {
                                                                                        return (
                                                                                            <img key={assignee.id} className="Assignee" src={assignee.avatar_url} />
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </ListItem>
                                                                </Paper>
                                                                <Divider />
                                                            </div>

                                                        )
                                                    })
                                                }

                                            </List>
                                        
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwipeableViews>
                </div>
            </Drawer>
        )
    }
}

AddCardsPanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddCardsPanel));
