import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import store from "./redux/store/index";

import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from "aws-amplify"


// Global.Auth

window.addEventListener("__ALLOW_AMPLIFY_GITHUB_CONNECTION__", (e) => {

    //TODO: Add a success callback in the configure function maybe?

    Amplify.configure({
        API: {
            graphql_endpoint: process.env.REACT_APP_AVTEC ? process.env.REACT_APP_GITHUB_ENDPOINT + '/api/graphql' : process.env.REACT_APP_GITHUB_ENDPOINT + '/graphql',
            graphql_headers: async () => ({
                'authorization': 'Bearer ' + e.detail.eid
            })
        }
    });

})



ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
