import React, { Component, PureComponent } from 'react';
import { MdFlag } from 'react-icons/md';

import { Draggable } from 'react-beautiful-dnd';
import '../../styles/common.card.css';

class CardComponent extends PureComponent {

    state = {
        smallScreen: false
    }

    constructor(props) {
        super(props);
    }

    calculateFontColor = (color) => {
        var r = color.substring(0, 2);
        var g = color.substring(2, 4);
        var b = color.substring(4)
        var calcVal = parseInt(r, 16) * 0.299 + parseInt(g, 16) * 0.587 + parseInt(b, 16) * 0.114;
        if (calcVal > 127) {
            return '#000';
        }
        else {
            return '#fff';
        }
    }

    stopPropagation = (e) => {
        e.stopPropagation()
    }

    render() {

        let card = this.props.card || {};
        let isClosed = card && card.content && card.content.state === "CLOSED" ? true : false;
        let repositoryName = card && card.content ? card.content.repository.name : 'no repo';
        let milestone = card && card.content && card.content.milestone;
        let issueTitle = card && card.content ? card.content.title : '';
        let number = card && card.content ? card.content.number : 'no number';
        let url = card && card.content ? card.content.url : 'no url';
        let labels = card && card.content ? card.content.labels : { nodes: [] };
        let assignees = card && card.content ? card.content.assignees : { nodes: [] };
        let reviews = card && card.content ? card.content.reviews : { nodes: [] };
        let reviewRequests = card && card.content ? card.content.reviewRequests : { nodes: [] };
        let typename = card && card.content ? card.content.__typename : "";

        if (this.props.restApi) {
            repositoryName = card && card.repository_url ? card.repository_url.substring(card.repository_url.lastIndexOf("/") + 1, card.repository_url.length) : 'no repo';
            milestone = card && card.milestone
            issueTitle = card && card.title ? card.title : '';
            number = card && card.number ? card.number : 'no number';
            url = card && card.html_url ? card.html_url : 'no url';
            labels = card && card.labels ? card.labels : [];
            assignees = card && card.assignees ? card.assignees : [];
        }



        return (
            <div>
                <div className="HeaderBar">
                    <a className="Link" onClick={this.stopPropagation} href={url} target="_blank">
                        {number}
                    </a>
                    <div className="RepoName truncate">
                        {repositoryName}
                    </div>
                    <div className="AssigneeContainer layout horizontal center">
                        {
                            assignees && (assignees.nodes || assignees).map((assignee, index, numAssignees) => {
                                if (index < 2) {
                                    return (
                                        <img key={assignee.id} className="Assignee" src={assignee.avatarUrl || assignee.avatar_url} />
                                    )
                                }
                                else if (index === 2 && (assignees.nodes || assignees).length > 3) {
                                    return (
                                        <div key={assignee.id} className="OverflowAssignee">
                                            +{(assignees.nodes || assignees).length - 2}
                                        </div>
                                    )
                                }
                                else if (index === 2) {
                                    return (
                                        <img key={assignee.id} className="Assignee" src={assignee.avatarUrl || assignee.avatar_url} />
                                    )
                                }
                            })
                        }
                    </div>
                </div>
                <div className="CardBody">
                    {issueTitle}
                    {/* [Framework] New users receiving 404s for various endpoints and a bunch bunch more text right here where the text is in this issue title which probably shouldn't be this long but it is and it has to work */}
                </div>
                <div className="Footer">
                    {
                        labels && (labels.nodes || labels).map((label) => {
                            return (
                                <div key={label.id} className="LabelCard" style={{ backgroundColor: '#' + label.color }}>
                                    <p key={label.id} className="LabelText" style={{ color: this.calculateFontColor(label.color) }}>
                                        {label.name}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    milestone ? 
                    <div className="layout horizontal center milestone-details">
                        <MdFlag />
                        <span className="spacer-4"></span>
                            <a onClick={this.stopPropagation} target="_blank" href={milestone.html_url || milestone.url}> {milestone.title} </a>
                    </div>
                    : ""
                }


            </div>
        )
    }
};

export default CardComponent;