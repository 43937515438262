import { SEARCH_REPO, SEARCH_LABELS_WITHIN_REPO } from "../../graphql/SearchRepo";
import { API, graphqlOperation } from 'aws-amplify'


export function searchRepo (queryString, callback) {

    return async function(dispatch) {
        //TODO: Change this
        var searchRes = await API.graphql(graphqlOperation(SEARCH_REPO, {queryString: queryString}))
        console.log(searchRes)

        if(callback) {
            callback(searchRes.data && searchRes.data.search && searchRes.data.search.edges)
        }
    }

}

export function getLabelsForRepo(name, owner, callback) {

    return async function (dispatch) {
        //TODO: Change this
        var searchRes = await API.graphql(graphqlOperation(SEARCH_LABELS_WITHIN_REPO, { owner: owner, name: name }))
        console.log(searchRes)

        if (callback) {
            callback(searchRes.data && searchRes.data.repository && searchRes.data.repository.labels && searchRes.data.repository.labels.nodes)
        }
    }

}