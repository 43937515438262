import {
    GET_PROJECT,
    UPDATE_HOPPER_STATE
} from "../constants/action-types";

import { GET_PROJECT_DETAIL } from "../../graphql/GetProjectDetail";
import { API, graphqlOperation } from 'aws-amplify'
import { func } from "prop-types";


/**
 * 
 * The purpose of this method is to parallely request cards for all the column. 
 * The number of cards they will request at a maximum is 100. 
 * If a column has > 100 cards it will start another round of request but only after the first round is done
 * 
 *
 * @param {Object} payload The selected project from the sidebar
 * @param {Object} currentProjectInfo The current state of `currentProjectInfo` object
 * @param {function} dispatch The function used to reflect state change in the reducer.
 * 
 * @returns {void}
 * 
 */

export async function ColumnProcessor(getState, currentProject, currentProjectInfo, dispatch, callback) {
    /**
     * Column-First Strategy
     * 
     * The way this strategy works is it will list the set of selected columns first and then make a call to get all the cards.
     * 
     * Why this strategy is good?
     * The user at all times is let known whats going on. It will build up to a better UX. 
     * Also waiting for all data at once will result in a likely 10 second wait and we dont want that.
     * Also this strategy puts lets pressure on Github Servers to `give` all data at once. 
     * This strategy will give them `time to breathe`.
     * 
    */

    // for (const item of [currentProjectInfo[0], currentProjectInfo[1], ]) {

    // Manage the Callback: Only want to call it once, when all of the data is recieved
    let cbNum = 0;
    let cbData = [];

    const cb = (err, data) => {
        if (err) {
            callback(err);
        } else {
            cbNum -= 1; // Decrement when this callback is called (when data is recieved)
            cbData.push(data);
        }
        // We should have the same number of callback calls, as the number of times we called the `callForData` function
        if (cbNum === 0) {
            console.log("Calling Callback...");
            callback(null, cbData);
        }
    }

    // Call the callback if there isn't anything to process
    if (!currentProjectInfo || !currentProjectInfo.length) {
        callback(null, []);
        return;
    }
    
    currentProjectInfo.forEach((column, columnIndex) => {
        if (columnIndex) {
            cbNum += 1;
            GetSingleColumn(getState, currentProject, currentProjectInfo, columnIndex, dispatch, null, null, cb);
        }
        // columnIndex && GetSingleColumn(getState, currentProject, currentProjectInfo, columnIndex, dispatch)
    })

}

/**
 * 
 * 
*/

export function GetSingleColumn(getState, currentProject, currentProjectInfo, columnIndex, dispatch, cardCursor, refreshColumn, callback) {
    var func = API.graphql(graphqlOperation(GET_PROJECT_DETAIL,
        {
            name: currentProject.name,
            number: currentProject.number,
            org: currentProject.org,
            cursor: columnIndex === 0 ? undefined : currentProjectInfo[columnIndex - 1].cursor,
            cardCursor: cardCursor && cardCursor.cursor ? cardCursor.cursor : undefined
        }
    ));

    func
        .then(function (projectInfo) {
            var projectInfoPayload = projectInfo.data.organization.project;
            var found = false;

            // If you try to load another project while the previous one is still loading, throw an error
            if (getState().currentProject.id !== projectInfoPayload.id) {
                return;
            } 

            currentProjectInfo.forEach((item, index) => {
                if (item.node.id === projectInfoPayload.columns.edges[0].node.id) {
                    found = true;
                    if (!currentProjectInfo[index].node.cards.edges) {
                        currentProjectInfo[index] = projectInfoPayload.columns.edges[0];
                    } else {
                        // This means to get latest data
                        refreshColumn && (currentProjectInfo[index].node.cards.edges = []);

                        currentProjectInfo[index].node.cards.edges = currentProjectInfo[index].node.cards.edges.concat(projectInfoPayload.columns.edges[0].node.cards.edges)
                    }

                    // If the total count is more than the data received start another request
                    if (projectInfoPayload.columns.edges[0].node.cards.totalCount > currentProjectInfo[index].node.cards.edges.length) {
                        GetSingleColumn(getState, currentProject, currentProjectInfo, index, dispatch, currentProjectInfo[index].node.cards.edges[currentProjectInfo[index].node.cards.edges.length - 1])
                    }

                } 
            })
            !found && currentProjectInfo.push(projectInfoPayload.columns.edges[0]);

            //Loop through each card and 

            dispatch({ type: UPDATE_HOPPER_STATE, payload: filterExistingCards(getState().hopperIssues, currentProjectInfo) });
            dispatch({ type: GET_PROJECT, payload: currentProjectInfo });

            if (callback) callback(null, currentProjectInfo);
        })
        .catch((err) => {
            console.error(err);
            if (callback) callback(err);
        })
}

function filterExistingCards(issues, currentProjectInfo) {

    var filteredIssues = [];

    if (currentProjectInfo && currentProjectInfo.length > 0) {

        for (let k = 0; k < issues.length; k++) {
            let issue = issues[k];
            var found = false;


            for (let i = 1; i < currentProjectInfo.length; i++) {
                let column = currentProjectInfo[i];

                if (column.node && column.node.cards && column.node.cards.edges) {
                    var edges = column.node.cards.edges;
                    for (let j = 0; j < edges.length; j++) {
                        let edge = edges[j];

                        if (edge.node && edge.node.content) {
                            // this is an issue/pr
                            let issue_pr = edge.node;

                            if ((issue.id === issue_pr.content.databaseId) || (issue.pull_request && (issue.pull_request.html_url === issue_pr.content.url))) {
                                found = true;
                                break
                            }
                        }
                    }
                }

                if (found) break;
            }

            if (!found) {
                filteredIssues.push(issue)
            }
        }

    }
    return filteredIssues;
}

